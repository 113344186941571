import { ReactComponent as BatteryChargingIco } from '../../assets/battery-charging.svg';
import { ReactComponent as SpinnerIco } from '../../assets/spinner3.svg';
import Badge, { DotBadge } from '../../components-v2/badge/badge';
import { rateTypeColor, SESSION_STATUS_COLORS, sessionStatusTranslation } from '../../components-v2/business-components/domain-mappings';
import { DataTableLayout, sortChecker, sortHandler } from '../../components-v2/data-table/data-table';
import Ico from '../../components-v2/ico';
import Box, { contrastColor, Divider } from '../../components-v2/utils';
import { NodeHierarchyLabelInverted } from '../../components/org-hierarchy/node-hierarchy-label';
import NodeLabel from '../../components/org-hierarchy/node-label';
import { rateTypeTranslation } from '../../components/tag';
import api from '../../services/api';

import {
  LmFmChargingSessionListItemDto,
  LmFmChargingSessionListResultDto,
  LmFmChargingSessionSumsResultDto,
  RateServiceType,
  SessionStatus,
} from '../../services/api-client/csp-api';
import { useAuth } from '../../utils/AuthProvider';
import { endOfDay, getTimezone, startOfDay } from '../../utils/date';
import { formatEnergy, formatPrice } from '../../utils/format';
import useBetterTranslate from '../../utils/translation-utils';
import uniqueLineIdentifier from '../../utils/unique-line-ident';
import { ChargingSessionsListPage, ExportSessionsArgs, FilterParameters, SessionTimeCell } from './charging-sessions-list-page';
import styles from './charging-sessions-list-page.module.scss';

const SERVICE_TYPE_ALLOWED_VALUES = [RateServiceType.WORK, RateServiceType.EMPLOYEE, RateServiceType.HOME, RateServiceType.PUBLIC, RateServiceType.UNDEFINED];

export default function LmFmChargingSessionsListPage() {
  const SORT_BY_VALUES = ['evseId', 'cardNumber', 'purchaseCost', 'totalCost'];
  const { _t } = useBetterTranslate('lmfm-charging-sessions-list-page');
  const { user } = useAuth();

  const sessionsApiCall = async (filter: FilterParameters) => {
    const apiFilter = toSearchApiFilter(filter);
    if (!apiFilter) return undefined;

    const resp = await api.lmfmChargingSessions.list(apiFilter, { cancelToken: uniqueLineIdentifier() });
    return resp;
  };

  const sumsApiCall = async (filter: FilterParameters) => {
    const apiFilter = toSearchApiFilter(filter);
    if (!apiFilter) return undefined;

    const resp = await api.lmfmChargingSessions.sums(apiFilter, { cancelToken: uniqueLineIdentifier() });
    return resp;
  };

  const exportApiCall = async (filterParams: FilterParameters, args: ExportSessionsArgs, cancelToken: string) => {
    const apiFilter = toSearchApiFilter(filterParams);
    if (!apiFilter) return undefined;

    return await api.lmfmChargingSessions.export(
      {
        filter: apiFilter,
        sessionFields: args.sessionFields,
        stationFields: args.stationFields,
        cardFields: args.cardFields,
        fileType: args.fileType,
        aggregateByStationId: args.aggregateByStationId,
        aggregateByStationLocation: args.aggregateByStationLocation,
        aggregateByCardId: args.aggregateByCardId,
        aggregateByCardLocation: args.aggregateByCardLocation,
        serviceTypeLabels: args.serviceTypeLabels,
        aggregationLabels: args.aggregationLabels,
        timezone: args.timezone,
      },
      { cancelToken }
    );
  };

  const toSearchApiFilter = (filter: FilterParameters) => {
    if (!filter.clientCode) return undefined;
    let consumedEnergyFrom = filter.energyFrom;
    if (consumedEnergyFrom !== undefined) {
      consumedEnergyFrom = consumedEnergyFrom * 1000;
    }
    let consumedEnergyTo = filter.energyTo;
    if (consumedEnergyTo !== undefined) {
      consumedEnergyTo = consumedEnergyTo * 1000;
    }
    const apiFilter = {
      client: filter.clientCode,
      hierarchyNodeCodes: filter.nodes || [filter.clientCode],
      limit: Number(filter.limit || 20),
      skip: Number(filter.skip || 0),
      from: startOfDay(filter.from),
      to: endOfDay(filter.to),
      entityFilter: filter.entity || [],
      serviceTypes: filter.serviceType as RateServiceType[],
      sortBy: filter.sortBy as any,
      sortDesc: filter.sortDesc === '1',
      energy: { from: consumedEnergyFrom, to: consumedEnergyTo },
      purchaseCost: { from: filter.purchaseCostFrom, to: filter.purchaseCostTo },
      totalCost: { from: filter.totalCostFrom, to: filter.totalCostTo },
      status: filter.status as SessionStatus[],
      stationIsPublic: filter.public === '1' ? true : undefined,
    };
    return apiFilter;
  };

  const energyApiCall = async (clientCode?: string, from?: string, to?: string, nodes?: string[]) => {
    if (!clientCode) return undefined;
    const resp = await api.lmfmChargingSessions.energyConsumption(
      {
        client: clientCode,
        hierarchyNodeCodes: nodes || [clientCode],
        from: startOfDay(from),
        to: endOfDay(to),
        timezone: getTimezone(),
      },
      { cancelToken: uniqueLineIdentifier() }
    );
    return resp;
  };

  const searchEntityTags = async (clientCode: string | undefined, txt: string) => {
    if (!clientCode) return [];
    const entityFilterRes = await api.lmfmChargingSessions.searchFilters({ q: txt, clientCode }, { cancelToken: uniqueLineIdentifier() });
    return entityFilterRes.data;
  };

  return (
    <ChargingSessionsListPage<LmFmChargingSessionListItemDto, LmFmChargingSessionListResultDto, LmFmChargingSessionSumsResultDto>
      pagePrefix={'lmfm'}
      sortValues={SORT_BY_VALUES}
      totalCostFilter={true}
      purchaseCostFilter={true}
      isStationPublicFilter={true}
      serviceTypes={SERVICE_TYPE_ALLOWED_VALUES}
      sessionsApiCall={sessionsApiCall}
      sumsApiCall={sumsApiCall}
      energyApiCall={energyApiCall}
      searchEntityTags={searchEntityTags}
      exportFieldsConfig={{ showStations: true, showCards: true }}
      exportApiCall={exportApiCall}
      tableLayout={(sessionsResp, filterParams, setFilterParams, sumsResp) => {
        const hierarchy = sessionsResp?.allClientHierarchy || [];
        const result: DataTableLayout<LmFmChargingSessionListItemDto> = [
          {
            width: '3fr',
            header: {
              onSort: sortHandler('evseId', setFilterParams),
              sorting: sortChecker('evseId', filterParams),
              testId: 'header_evseId',
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('EVSE-ID')}</Box>
                  <Box>{_t('Location')}</Box>
                  <Box>{_t('Area')}</Box>
                </Box>
              ),
            },
            cell: {
              node: (record) => {
                return (
                  <Box gap='xs' kind={'vflex'}>
                    <Box fw='600'>{record.evseId}</Box>
                    {record.stationClientCode === sessionsResp?.clientContext.code ? (
                      <>
                        <NodeHierarchyLabelInverted allNodes={hierarchy} code={record.stationClientNodeCode} hideArea={true} hideClientRoot={true} />
                        <NodeLabel allNodes={hierarchy} code={record.stationClientNodeCode} />
                      </>
                    ) : (
                      <>
                        <div className={styles.warning}>{record.stationClientCode}</div>
                        <div>{'-'}</div>
                      </>
                    )}
                  </Box>
                );
              },
            },
          },
          {
            width: '3fr',
            header: {
              onSort: sortHandler('cardNumber', setFilterParams),
              sorting: sortChecker('cardNumber', filterParams),
              testId: 'header_cardNumber',
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('Badge Number')}</Box>
                  <Box>{_t('Badge Label')}</Box>
                  <Box>{_t('Cost Center')}</Box>
                </Box>
              ),
            },
            cell: {
              node: (record) => {
                const hasAccess = record.cardNumber || record.cardLabel;
                return (
                  <>
                    {hasAccess && (
                      <Box gap='xs' kind={'vflex'}>
                        <Box fw='700' data-cy={`el_cardNumber`}>
                          {record.cardNumber || '-'}
                        </Box>
                        <Box data-cy={`el_cardLabel`}>{record.cardLabel || '-'}</Box>
                        <Box data-cy={`el_cardComment`}>{record.cardComment || '-'}</Box>
                      </Box>
                    )}

                    {!hasAccess && record.cardClientCode === sessionsResp?.clientContext.code && (
                      <Box gap='xs' kind={'vflex'}>
                        <NodeHierarchyLabelInverted allNodes={hierarchy} code={record.cardClientNodeCode} hideLocation={true} hideArea={true} hideClientRoot={true} />
                        <NodeHierarchyLabelInverted allNodes={hierarchy} code={record.cardClientNodeCode} hideRegion={true} hideArea={true} hideClientRoot={true} />
                        <div>{record.cardComment || '-'}</div>
                      </Box>
                    )}

                    {!hasAccess && record.cardClientCode && record.cardClientCode !== sessionsResp?.clientContext.code && (
                      <Box gap='xs' kind={'vflex'}>
                        <div className={styles.warning}>{record.cardClientCode || '-'}</div>
                        <div>{'-'}</div>
                        <div>{record.cardComment || '-'}</div>
                      </Box>
                    )}
                  </>
                );
              },
            },
          },

          {
            width: '3fr',
            header: {
              onSort: sortHandler('startTime', setFilterParams),
              sorting: sortChecker('startTime', filterParams),
              testId: 'header_startTime',
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('Start')}</Box>
                  <Box>{_t('End')}</Box>
                  <Box>{_t('Duration')}</Box>
                </Box>
              ),
            },
            cell: {
              node: (record) => <SessionTimeCell startTime={record.startTime} endTime={record.endTime} />,
            },
          },

          {
            width: '2fr',
            header: {
              onSort: sortHandler('rateServiceType', setFilterParams),
              sorting: sortChecker('rateServiceType', filterParams),
              testId: 'header_rateServiceType',
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('Used')}</Box>
                  <Box fw='800'>{_t('Service')}</Box>
                </Box>
              ),
            },
            cell: {
              node: (record) => (
                <Box h='100%' kind={'vflex'} justify='center'>
                  <Badge testId={'el_service'} fg={contrastColor(rateTypeColor(record.rateServiceType))} bg={rateTypeColor(record.rateServiceType)}>
                    {rateTypeTranslation(record.rateServiceType)}
                  </Badge>
                </Box>
              ),
            },
          },

          {
            width: '2fr',
            header: {
              onSort: sortHandler('energy', setFilterParams),
              sorting: sortChecker('energy', filterParams),
              testId: 'header_energy',
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('Charged')}</Box>
                  <Box fw='800'>{_t('Energy')}</Box>
                  <Box>{_t('(kWh)')}</Box>
                </Box>
              ),
            },
            subHeader: {
              node: () => (
                <Box kind={'vflex'} justify='center' align='flex-end' fw='700'>
                  {!sumsResp ? <SpinnerIco /> : <div>{formatEnergy(sumsResp.totalEnergy, user?.preferences.languageCode, 2)}</div>}
                </Box>
              ),
            },
            cell: {
              node: (record) => (
                <Box h='100%' kind={'vflex'} justify='center' align='flex-end' testId='el_energy'>
                  {formatEnergy(record.energy, user?.preferences.languageCode, 2)}
                </Box>
              ),
            },
          },

          {
            width: '2fr',
            header: {
              onSort: sortHandler('purchaseCost', setFilterParams),
              sorting: sortChecker('purchaseCost', filterParams),
              testId: 'header_refund',
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('Refund')}</Box>
                  <Box>{_t('(€)')}</Box>
                </Box>
              ),
            },
            subHeader: {
              node: () => (
                <Box kind={'vflex'} justify='center' align='flex-end' fw='700'>
                  {!sumsResp ? <SpinnerIco /> : <div>{formatPrice(sumsResp.purchaseCost, user?.preferences.languageCode, 2)}</div>}
                </Box>
              ),
            },
            cell: {
              node: (record) => (
                <Box h='100%' kind={'vflex'} justify='center' align='flex-end' testId='el_refund'>
                  {formatPrice(record?.purchaseCost, user?.preferences.languageCode, 2)}
                </Box>
              ),
            },
          },

          {
            width: '2fr',
            header: {
              onSort: sortHandler('totalCost', setFilterParams),
              sorting: sortChecker('totalCost', filterParams),
              testId: 'header_cost',
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('Cost')}</Box>
                  <Box>{_t('(€)')}</Box>
                </Box>
              ),
            },
            subHeader: {
              // span: 2,
              node: () => (
                <Box kind={'vflex'} justify='center' align='flex-end' fw='700'>
                  {!sumsResp ? <SpinnerIco /> : <div>{formatPrice(sumsResp.totalCost, user?.preferences.languageCode)}</div>}
                </Box>
              ),
            },
            cell: {
              node: (record) => (
                <Box h='100%' kind={'vflex'} justify='center' align='flex-end' testId='el_cost'>
                  {formatPrice(record?.totalCost, user?.preferences.languageCode, 2)}
                </Box>
              ),
            },
          },

          {
            width: '2fr',
            header: {
              testId: 'header_status',
              node: () => (
                <Box kind={'vflex'}>
                  <Box fw='800'>{_t('Status')}</Box>
                </Box>
              ),
            },
            cell: {
              node: (record) => (
                <Box h='100%' kind={'vflex'} justify='center' testId='el_status' gap='xs'>
                  <DotBadge circleColor={SESSION_STATUS_COLORS[record.status]}>{sessionStatusTranslation(record.status)}</DotBadge>
                  {record.status === SessionStatus.Charging && record.soc && (
                    <>
                      <Divider kind='h' />
                      <Box kind={'hflex'} gap='xs' align='center' tooltip={_t('The State of Charge can only be displayed for DC chargers, that are configured accordingly')}>
                        <Ico file={<BatteryChargingIco />} size='24px' />
                        <Box fw='600'>{record.soc.toFixed(0)}%</Box>
                        <Box>(SoC)</Box>
                      </Box>
                    </>
                  )}
                </Box>
              ),
            },
          },
        ];

        return result;
      }}
    />
  );
}
