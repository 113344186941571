import { useEffect, useState } from 'react';
import { ReactComponent as ThreeDotsIco } from '../../../../assets/dots-three.svg';
import { ReactComponent as UserNewIco } from '../../../../assets/user-new.svg';
import { ReactComponent as HierarchyIco } from '../../../../assets/hierarchy.svg';

import { Item, Menu, Separator, useContextMenu } from 'react-contexify';
import { useDebounce } from 'react-use';
import { ReactComponent as ChargerIco } from '../../../../assets/charging-station-blue.svg';
import { ReactComponent as UserAddIco } from '../../../../assets/user-new.svg';
import { ReactComponent as CheckIco } from '../../../../assets/check.svg';
import { ReactComponent as ViewRoleNodesIco } from '../../../../assets/hierarchy-picker.svg';
import { ReactComponent as InfoIco } from '../../../../assets/info.svg';
import { ReactComponent as MagnifyingGlassIco } from '../../../../assets/magnifying-glass.svg';
import { ReactComponent as EditRoleNodesIco } from '../../../../assets/pen.svg';
import { ReactComponent as DeleteRoleIco } from '../../../../assets/trash.svg';
import { ReactComponent as UsersIco } from '../../../../assets/users.svg';
import { ClientChoosePopup, useClientChooser } from '../../../../components-v2/business-components/client-choose-popup/client-choose-popup';
import ButtonV2 from '../../../../components-v2/button';
import DataTableV2, { sortChecker, sortHandler } from '../../../../components-v2/data-table/data-table';
import Ico, { Circle } from '../../../../components-v2/ico';
import InputV2, { FormContainerV2 } from '../../../../components-v2/input';
import { ApiBasedContent, Page, PageContent, PageHeader, PageTitle } from '../../../../components-v2/page/page';
import Pagingation from '../../../../components-v2/pagination/pagination';
import TabPanel, { Tab, TabContent, Tabs } from '../../../../components-v2/tab-panel';
import Box, { Divider } from '../../../../components-v2/utils';
import { NodeSelectionState, OrgDiagrammNodeProp } from '../../../../components/org-hierarchy/org-diagramm-utils';
import { OrgHierarchyDiagrammContext } from '../../../../components/org-hierarchy/org-hierarchy-diagramm';
import RolePermissionsPopup from '../../../../components/role-permissions-popup';
import { useApi } from '../../../../hooks/useApi';
import { useBetterNavigate } from '../../../../hooks/useBetterNavigate';
import { usePageParams } from '../../../../hooks/usePageParams';
import api from '../../../../services/api';
import { OrgUserDto, OrgUserRoleDto, Permission, RoleType, SharedClientContext } from '../../../../services/api-client/csp-api';
import { pagingValidator, validateString } from '../../../../utils/queryParamValidators';
import useBetterTranslate from '../../../../utils/translation-utils';
import { PermissionsGroupsOfRole, getPermissionGroupStructure } from '../../../administration/roles/role-create-popup/permissions-groups';
import OrganisationHierarchyView from '../../shared/organisation-hierarchy-view';
import OrgUserAddRolesPopup from '../user-add-roles-popup/org-user-add-roles-popup';
import OrgUserDeleteRolePopup from '../user-delete-role-popup/org-user-delete-role-popup';
import OrgUserDeletePopup from '../user-delete-user-popup/org-user-delete-popup';
import OrgUserEditInfoPopup, { UserAccount } from '../user-edit-info-popup/user-edit-info-popup';
import OrgUserEditRolePopup from '../user-edit-role-popup/org-user-edit-role-popup';
import styles from './org-users-list-page.module.scss';

// const SHOW_ROLES_LIMIT = 3;
// const MAX_HIERARCHY_DEPTH = 4; // Root Node, Region, Location, Area

enum UserMode {
  CREATE_NEW = 'create-new',
  EDIT_EXISTING = 'edit-existing',
  ASSIGN_TO_CLIENT = 'assign-to-client',
}

export default function OrgUsersListPage() {
  const { _t } = useBetterTranslate('org-user-list-popup');
  const navigate = useBetterNavigate<FilterParameters>();

  const SORT_ALLOWED_VALUES = ['firstName', 'mail'];
  const sortingValidator = {
    sortBy: validateString(SORT_ALLOWED_VALUES),
  };

  type FilterParameters = {
    skip?: number | null;
    limit?: number | null;
    search?: string | undefined;
    sortBy?: string;
    sortDesc?: string;
    clientCode?: string;
    roles?: string[];
  };

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, { ...pagingValidator, ...sortingValidator });
  const [searchTerm, setSearchTerm] = useState(filterParams.search);
  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };
  const [editUser, setEditUser] = useState<OrgUserDto>();
  const { show: showUserContextMenu } = useContextMenu({ id: 'user-edit-actions' });

  const [showChangeClientPopup, changeClientPopupProps] = useClientChooser();

  const [expandedRecords, setExpandedRecords] = useState<String[]>([]);
  const allPermissions = Object.values(Permission).map((k) => k as Permission);

  const [usersRes, usersFetching, usersErr, refreshResult] = useApi(
    {
      call: async (clientCode?: string, skip?: number | null, limit?: number | null, query?: string | null, roleIds?: string[], sortBy?: any, sortDesc?: string) => {
        if (!clientCode) return undefined;
        const res = await api.orgUsers.search({
          clientCode: clientCode,
          limit: Number(limit || 20),
          skip: Number(skip || 0),
          search: query || undefined,
          roleIds: roleIds,
          sortBy,
          sortDesc: sortDesc === '1',
        });
        return res;
      },
      map: (data) => {
        if (!data) return data;
        setExpandedRecords([]);
        setClientContext(data.clientContext);
        return data;
      },
    },
    filterParams.clientCode,
    filterParams.skip,
    filterParams.limit,
    filterParams.search,
    filterParams.roles,
    filterParams.sortBy,
    filterParams.sortDesc
  );

  useDebounce(
    () => {
      if (searchTerm === filterParams.search) return;
      setFilterParams({ search: searchTerm });
    },
    800,
    [searchTerm, filterParams.search]
  );

  useEffect(() => {
    setSearchTerm(filterParams.search);
  }, [filterParams.search]);

  const [clientContext, setClientContext] = useState<SharedClientContext>();
  const [selectedNodes, setSelectedNodes] = useState<OrgDiagrammNodeProp[]>([]);

  //user states
  const [currentUser, setCurrentUser] = useState<OrgUserDto>();
  const [userMode, setUserMode] = useState<UserMode>();
  const [showUserAccountPopup, setShowUserAccountPopup] = useState(false);
  const [showUserDeletePopup, setShowUserDeletePopup] = useState(false);

  //role states
  const [currentRole, setCurrentRole] = useState<OrgUserRoleDto>();
  const [editRoleMode, setEditRoleMode] = useState(false);
  const [showAddRolesPopup, setShowAddRolesPopup] = useState(false);
  const [showEditRolePopup, setShowEditRolePopup] = useState(false);
  const [showDeleteRolePopup, setShowDeleteRolePopup] = useState(false);
  const [deleteOnlyRoleMode, setDeleteOnlyRoleMode] = useState(false);

  //permission states
  const [showRolesPermissionsPopup, setShowRolesPermissionsPopup] = useState(false);
  const [currentPermissionGroups, setCurrentPermissionGroups] = useState<PermissionsGroupsOfRole>();

  //user functions
  const onAddUserClick = async () => {
    await setDataBeforeEdit(true);
    setUserMode(UserMode.CREATE_NEW);
    setShowUserAccountPopup(true);
  };

  const onEditUserClick = async (user: OrgUserDto) => {
    await setDataBeforeEdit(true, user);
    setUserMode(UserMode.EDIT_EXISTING);
    setShowUserAccountPopup(true);
  };

  const onDeleteUserClick = async (user: OrgUserDto) => {
    await setDataBeforeEdit(false, user);
    setShowUserDeletePopup(true);
  };

  const createUser = async (roleIds: string[], nodeCodes: string[]): Promise<void> => {
    if (!currentUser) return;
    await api.orgUsers.createUser({
      clientCode: usersRes?.clientContext.code || '',
      defaultLanguage: currentUser.defaultLanguage || 'en',
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      mail: currentUser.mail,
      roleIds,
      nodeCodes,
    });
    refreshResult();
    return;
  };

  const assignClientToExistingUser = async (roleIds: string[], nodeCodes: string[]): Promise<void> => {
    if (!currentUser) return;
    await api.orgUsers.addClientToUser({
      clientCode: usersRes?.clientContext.code || '',
      defaultLanguage: currentUser.defaultLanguage || 'en',
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      mail: currentUser.mail,
      roleIds,
      nodeCodes,
    });
    refreshResult();
    return;
  };

  const updateUserAccount = async (user: UserAccount): Promise<void> => {
    await api.orgUsers.updateUserAccount({
      defaultLanguage: user.defaultLanguage || 'en',
      clientCode: usersRes?.clientContext.code || '',
      firstName: user.firstName,
      lastName: user.lastName,
      mail: user.mail,
    });
    refreshResult();
    return;
  };

  const deleteUser = async (): Promise<void> => {
    if (!currentUser) return;
    await api.orgUsers.deleteUser({
      clientCode: usersRes?.clientContext.code || '',
      mail: currentUser.mail,
    });
    refreshResult();
    return;
  };

  //role functions
  const onViewRoleClick = async (user: OrgUserDto, role: OrgUserRoleDto) => {
    await setDataBeforeEdit(true, user, role);
    setEditRoleMode(false);
    setShowEditRolePopup(true);
  };

  const onEditRoleClick = async (user: OrgUserDto, role: OrgUserRoleDto) => {
    await setDataBeforeEdit(true, user, role);
    setEditRoleMode(true);
    setShowEditRolePopup(true);
  };

  const onAddRolesClick = async (user: OrgUserDto) => {
    await setDataBeforeEdit(true, user);
    setUserMode(UserMode.EDIT_EXISTING);
    setShowAddRolesPopup(true);
  };

  const getPermissionsOfRole = async (roleId: string) => {
    const res = await api.orgUsers.getPermissionsOfRole(filterParams.clientCode || '', roleId);
    return res.data.permissions;
  };

  const onRolesPermissionsClick = async (user: OrgUserDto, role: OrgUserRoleDto) => {
    await setDataBeforeEdit(false, user, role);
    setShowRolesPermissionsPopup(true);
  };

  const onDeleteRoleClick = async (user: OrgUserDto, role: OrgUserRoleDto) => {
    await setDataBeforeEdit(false, user, role);
    const isOnlyRoleResp = await api.orgUsers.checkIfOnlyRole({
      clientCode: usersRes?.clientContext.code || '',
      userMail: user.mail,
      roleId: role.id,
    });
    setDeleteOnlyRoleMode(isOnlyRoleResp.data);
    setShowDeleteRolePopup(true);
  };

  const updateRole = async (nodeCodes: string[]): Promise<void> => {
    if (!currentUser || !currentRole) return;
    await api.orgUsers.updateUserRole({
      clientCode: usersRes?.clientContext.code || '',
      userMail: currentUser.mail,
      roleId: currentRole.id,
      nodeCodes,
    });
    refreshResult();
  };

  const addRoles = async (roleIds: string[], nodeCodes: string[]): Promise<void> => {
    if (!currentUser) return;
    await api.orgUsers.addUserRoles({
      clientCode: usersRes?.clientContext.code || '',
      userMail: currentUser.mail,
      roleIds,
      nodeCodes,
    });
    refreshResult();
  };

  const deleteRole = async (): Promise<void> => {
    if (!currentUser || !currentRole) return;
    await api.orgUsers.deleteUserRole({
      clientCode: usersRes?.clientContext.code || '',
      userMail: currentUser.mail,
      roleId: currentRole.id,
    });
    refreshResult();
  };

  const setDataBeforeEdit = async (refresh: boolean, user?: OrgUserDto, role?: OrgUserRoleDto) => {
    let clientContext = usersRes?.clientContext;
    let currentUser = user;
    let currentRole = role;
    if (refresh) {
      if (user) {
        const resp = await api.orgUsers.getUser(filterParams.clientCode || '', user.mail);
        clientContext = resp.data.clientContext;
        currentUser = resp.data.user;
        if (role) {
          currentRole = resp.data.user.roles.find((item) => item.id === role.id);
        }
      } else {
        const resp = await api.orgUsers.getClientContext(filterParams.clientCode || '');
        clientContext = resp.data;
      }
    }
    setClientContext(clientContext);
    setCurrentUser(currentUser);
    setCurrentRole(currentRole);

    if (currentRole) {
      const permissions = await getPermissionsOfRole(currentRole?.id || '');
      const groups = getPermissionGroupStructure(permissions);
      setCurrentPermissionGroups({ roleId: currentRole?.id || '', roleName: currentRole?.name || '', roleType: currentRole?.type as RoleType, permissionsGroups: groups });
    }
  };

  const onItemClick = (ctx: OrgHierarchyDiagrammContext, node: OrgDiagrammNodeProp, others: OrgDiagrammNodeProp[]) => {
    if (node.selected === NodeSelectionState.Full) {
      node.selected = NodeSelectionState.None;
    } else {
      node.selected = NodeSelectionState.Full;
    }
    setSelectedNodes(others.filter((n) => n.selected === NodeSelectionState.Full));
    ctx.refresh();
  };

  const isRoleHilighted = (role: OrgUserRoleDto): boolean => {
    const codes = role.nodeCodes || [];
    const isSelected = selectedNodes.some((node) => codes.includes(node.code));
    return isSelected;
  };

  const showUser = (user: OrgUserDto) => {
    // show all if there are no nodes selected
    if (selectedNodes.length <= 0) return true;

    const selectedNodeCodes = selectedNodes.map((node) => node.code);
    return user.roles.some((role) => selectedNodeCodes.some((nodeCode) => role.nodeCodes.includes(nodeCode)));
  };

  return (
    <Page>
      <PageHeader>
        <PageTitle>{_t('Organisation')}</PageTitle>
      </PageHeader>
      <PageContent>
        <Box kind={'hflex'} gap='l' align='center'>
          <Box fw='500' fs={'l'}>
            {_t(`Edit Organisation Users`)}
          </Box>
          <Divider kind='v' />
          <Box>{usersRes?.organization?.title}</Box>
          <Box kind={'hflex'} justify='flex-end' flexGrow='1'>
            {usersRes && (
              <ButtonV2
                apperance='primary'
                onClick={async () => {
                  const resp = await showChangeClientPopup({
                    searchClients: (search) => api.profile.accessibleClientNodes({ search: search, permissions: allPermissions }),
                    clientContext: usersRes.clientContext,
                  });

                  if (resp) {
                    navigate(`/organisation/users/${resp}`);
                    window.location.reload();
                  }
                }}
              >
                {_t('Switch client organisation')}
              </ButtonV2>
            )}
          </Box>
        </Box>

        <>
          <TabPanel>
            <Tabs>
              <Tab
                fillIco
                dataCy='tab-structure'
                txt={_t('Structure')}
                ico={<HierarchyIco />}
                onClick={() => navigate(`/organisation/structure/${usersRes?.organization?.code}`)}
              />
              <Tab fillIco dataCy='tab-stations' txt={_t('Stations')} ico={<ChargerIco />} onClick={() => navigate(`/organisation/stations/${usersRes?.organization?.code}`)} />
              <Tab strokeIco dataCy='tab-users' active={true} txt={_t('Users')} ico={<CheckIco />} />
            </Tabs>
            <TabContent active className={styles.tabContent}>
              <Box kind={'vflex'} gap='l' h='100%' className={styles.diagramContainer}>
                <ApiBasedContent err={usersErr} fetching={usersFetching} resp={usersRes}>
                  {(usersRes) => {
                    return (
                      <Box pad={['0', '400']}>
                        <OrganisationHierarchyView
                          className={styles.diagram}
                          classNameDiagramm={styles.diagram}
                          fetching={usersFetching}
                          organisation={usersRes?.organization}
                          nodesSelectable={true}
                          accessableNodes={clientContext?.accessableNodes.map((n) => n.code)}
                          onItemClick={onItemClick}
                          selectedNodes={selectedNodes.map((n) => n.code)}
                          doNotPropagate={true}
                          rootNodeSelectable={true}
                        />
                      </Box>
                    );
                  }}
                </ApiBasedContent>
                <ApiBasedContent err={usersErr} fetching={usersFetching} resp={usersRes}>
                  {(usersRes) => {
                    return (
                      <Box h='100%'>
                        <Box kind={'vflex'} gap='l' h='100%'>
                          <Box kind={'hflex'} gap='l' justify='space-between' align='center' pad={['0', '400']}>
                            <FormContainerV2>
                              <InputV2
                                placeholder={_t('Search')}
                                icoSuffix={<Ico fill='primary-500' size='16px' file={<MagnifyingGlassIco />} />}
                                value={searchTerm || ''}
                                onChange={setSearchTerm}
                                type='text'
                              />
                            </FormContainerV2>
                            <ButtonV2 apperance='primary' onClick={onAddUserClick}>
                              <Box kind={'hflex'} gap='s' align='center'>
                                <Ico size='18px' fill='primary-white' file={<UserAddIco />} />
                                {_t('Create new user')}
                              </Box>
                            </ButtonV2>
                          </Box>

                          <Box kind={'vflex'} justify='space-between' h='100%'>
                            <DataTableV2
                              records={usersRes.users.filter((record) => showUser(record))}
                              layout={[
                                {
                                  width: '1fr',
                                  header: {
                                    node: () => <Box fw='800'>{_t('Name')}</Box>,
                                    onSort: sortHandler('firstName', setFilterParams),
                                    sorting: sortChecker('firstName', filterParams),
                                  },
                                  cell: {
                                    node: (record) => <Box fw='700'>{record.fullName}</Box>,
                                  },
                                },
                                {
                                  width: '1fr',
                                  header: {
                                    node: () => <Box fw='800'>{_t('E-Mail')}</Box>,
                                    onSort: sortHandler('mail', setFilterParams),
                                    sorting: sortChecker('mail', filterParams),
                                  },
                                  cell: {
                                    node: (record) => <Box>{record.mail}</Box>,
                                  },
                                },
                                {
                                  width: '2fr',
                                  header: {
                                    node: () => <Box fw='800'>{_t('Roles')}</Box>,
                                  },
                                  cell: {
                                    node: (record) => (
                                      <Box kind={'hflex'} gap='s'>
                                        <Box kind={'vflex'} gap='m' flexGrow='1'>
                                          {record.roles.map((role, idx) => {
                                            return (
                                              <Box
                                                key={idx}
                                                radius='8px'
                                                pad={'300'}
                                                bg={isRoleHilighted(role) ? 'primary-200' : 'brand-neutral-050'}
                                                kind={'hflex'}
                                                gap='m'
                                                align='center'
                                                className={isRoleHilighted(role) ? styles.roleHilighted : ''}
                                              >
                                                <Box kind={'hflex'} flexGrow='1' gap='xs' align='center'>
                                                  {isRoleHilighted(role) && <Circle color='primary-500' borderColor='primary-white' size='12px' />}
                                                  <Box>{role.name}</Box>
                                                  <Box onClick={() => onRolesPermissionsClick(record, role)}>
                                                    <Ico file={<InfoIco />} fill='primary-500' size='18px' />
                                                  </Box>
                                                </Box>
                                                <Divider kind='v' />
                                                <Box kind={'hflex'} gap='m'>
                                                  <Box onClick={() => onViewRoleClick(record, role)}>
                                                    <Ico size='24px' file={<ViewRoleNodesIco />} />
                                                  </Box>
                                                  <Box onClick={() => onEditRoleClick(record, role)}>
                                                    <Ico size='24px' file={<EditRoleNodesIco />} />
                                                  </Box>
                                                  <Box onClick={() => onDeleteRoleClick(record, role)}>
                                                    <Ico size='24px' file={<DeleteRoleIco />} />
                                                  </Box>
                                                </Box>
                                              </Box>
                                            );
                                          })}
                                        </Box>

                                        <Box>
                                          <Box
                                            kind={'hflex'}
                                            gap='m'
                                            pad={'300'}
                                            onClick={(ev) => {
                                              setEditUser(record);
                                              showUserContextMenu(ev);
                                            }}
                                          >
                                            <Divider kind='v' />
                                            <Box>
                                              <Ico size='24px' rotate={90} fill='primary-500' file={<ThreeDotsIco />} />
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    ),
                                  },
                                },
                              ]}
                            />
                          </Box>
                          <Pagingation
                            limit={usersRes.limit}
                            skip={usersRes.skip}
                            total={usersRes.total}
                            onChange={(arg) => {
                              setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
                            }}
                          />
                        </Box>
                      </Box>
                    );
                  }}
                </ApiBasedContent>
              </Box>
            </TabContent>

            <Menu id={`user-edit-actions`}>
              {editUser && (
                <>
                  <Item
                    onClick={() => {
                      onAddRolesClick(editUser);
                    }}
                  >
                    <Box kind={'hflex'} gap='m' align='center'>
                      <Ico size='16px' fill='primary-500' file={<UserNewIco />} />
                      <span>{_t('Add role')}</span>
                    </Box>
                  </Item>
                  <Separator />
                  <Item
                    onClick={() => {
                      onEditUserClick(editUser);
                    }}
                  >
                    <Box kind={'hflex'} gap='m'>
                      <Ico size='16px' fill='primary-500' file={<EditRoleNodesIco />} />
                      <span>{_t('Edit user')}</span>
                    </Box>
                  </Item>
                  <Separator />
                  <Item
                    onClick={() => {
                      onDeleteUserClick(editUser);
                    }}
                  >
                    <Box kind={'hflex'} gap='m'>
                      <Ico size='16px' fill='primary-500' file={<DeleteRoleIco />} />
                      <span>{_t('Delete user')}</span>
                    </Box>
                  </Item>
                </>
              )}
            </Menu>
          </TabPanel>
        </>
      </PageContent>

      <OrgUserEditInfoPopup
        open={showUserAccountPopup}
        user={userMode === UserMode.EDIT_EXISTING ? currentUser : undefined}
        clientCode={filterParams.clientCode || ''}
        defaultLang={usersRes?.defaultLang || 'en'}
        onCancel={() => setShowUserAccountPopup(false)}
        onSubmit={async (user) => {
          if (user.existing) {
            setUserMode(UserMode.ASSIGN_TO_CLIENT);
          }
          if (userMode === UserMode.CREATE_NEW || userMode === UserMode.ASSIGN_TO_CLIENT) {
            setCurrentUser({ ...user, fullName: `${user.firstName} ${user.lastName}`, defaultLanguage: user.defaultLanguage, roles: [] });
            setShowUserAccountPopup(false);
            setShowAddRolesPopup(true);
            return Promise.resolve();
          } else {
            await updateUserAccount(user);
            setShowUserAccountPopup(false);
          }
        }}
      />

      <OrgUserDeletePopup
        open={showUserDeletePopup}
        onCancel={() => setShowUserDeletePopup(false)}
        onSubmit={async () => {
          await deleteUser();
          setShowUserDeletePopup(false);
        }}
      />

      <OrgUserAddRolesPopup
        open={showAddRolesPopup}
        fullName={currentUser?.fullName || ''}
        clientContext={clientContext}
        clientRoles={usersRes?.clientRoles}
        disabledRoleIds={userMode === UserMode.CREATE_NEW ? [] : currentUser?.roles.map((role) => role.id)}
        onCancel={() => setShowAddRolesPopup(false)}
        onSubmit={async (roleIds: string[], nodeCodes: string[]) => {
          if (userMode === UserMode.ASSIGN_TO_CLIENT) {
            await assignClientToExistingUser(roleIds, nodeCodes);
          } else if (userMode === UserMode.CREATE_NEW) {
            await createUser(roleIds, nodeCodes);
          } else {
            await addRoles(roleIds, nodeCodes);
          }
          setShowAddRolesPopup(false);
        }}
        getPermissionsOfRole={getPermissionsOfRole}
      />

      <OrgUserEditRolePopup
        open={showEditRolePopup}
        fullName={currentUser?.fullName || ''}
        clientContext={clientContext}
        userRole={currentRole}
        edit={editRoleMode}
        onCancel={() => setShowEditRolePopup(false)}
        onSubmit={async (nodeCodes: string[]) => {
          if (editRoleMode) {
            await updateRole(nodeCodes);
          }
          setShowEditRolePopup(false);
        }}
        onDeleteRole={() => {
          setShowEditRolePopup(false);
          setShowDeleteRolePopup(true);
        }}
        permissionGroups={currentPermissionGroups?.permissionsGroups || []}
      />

      <RolePermissionsPopup
        open={showRolesPermissionsPopup}
        permissionsGroups={currentPermissionGroups?.permissionsGroups || []}
        onClose={() => {
          setShowRolesPermissionsPopup(false);
          setCurrentPermissionGroups(undefined);
        }}
        roleType={currentRole?.type as RoleType}
        roleName={currentRole?.name}
      />

      <OrgUserDeleteRolePopup
        open={showDeleteRolePopup}
        onlyRole={deleteOnlyRoleMode}
        onCancel={() => setShowDeleteRolePopup(false)}
        onSubmit={async () => {
          await deleteRole();
          setShowDeleteRolePopup(false);
        }}
      />

      <ClientChoosePopup {...changeClientPopupProps} />
    </Page>
  );
}
