import * as _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Accordion, { AccordionContent, AccordionExpandBtn, AccordionHead } from '../../components-v2/accordion';
import { DotBadge } from '../../components-v2/badge/badge';
import ButtonV2, { ButtonSwitch } from '../../components-v2/button';
import DataTable from '../../components-v2/data-table/data-table';
import { Modal, ModalAcceptButton, ModalCancelButton, ModalContent, ModalFooter, ModalHeader, useSimpleModal } from '../../components-v2/modal/modal';
import { Page, PageContent, PageHeader } from '../../components-v2/page/page';
import Box, { Colors, Divider, TableRowSkeleton } from '../../components-v2/utils';
import CheckBox from '../../components/input-check-box';
import api from '../../services/api';
import { formatter } from '../../utils/localized-types';
import sleep from '../../utils/sleep';

function prettyDateTime(str: string) {
  const m = moment(str).utc();
  return m.format('YYYY-MM-DD HH:mm:ss');
}

function prettyDay(str: string) {
  const m = moment(str);
  return m.format('YYYY-MM-DD');
}

function prettyMonth(str: string) {
  const m = moment(str);
  return m.format('YYYY-MM-DD');
}

function getRecordColor(record: { v1: number; v2: number }) {
  let diff = record.v2 - record.v1;
  let fg: Colors = 'green-kelly-green';
  if (diff > 0) {
    fg = 'blue-blue-jeans';
  } else if (diff < 0) {
    fg = 'red-light-salmon';
  }

  return fg;
}

interface MigrationMonthLog {
  month: string;
  expanded: boolean;
  totalRecords: number;
  completedRecords: number;
  msg?: string;
  days: {
    day: string;
    records: number;
    recordsV1: number;
  }[];
}

export function StatusLogMigrationPage() {
  const [migrationLog, setMigrationLog] = useState<MigrationMonthLog[]>([
    // {month: '2023-08', expanded: false, totalRecords: 0, completedRecords: 0, days: [
    //   {day: '2023-08-01', records: 0},
    //   {day: '2023-08-02', records: 1},
    //   {day: '2023-08-03', records: 3},
    //   {day: '2023-08-04', records: 0},
    // ]},
    // {month: '2023-09', expanded: false, totalRecords: 0, completedRecords: 0, days: [
    //   {day: '2023-09-01', records: 0},
    //   {day: '2023-09-02', records: 1},
    //   {day: '2023-09-03', records: 3},
    //   {day: '2023-09-04', records: 0},
    // ]},
    // {month: '2023-10', expanded: false, totalRecords: 0, completedRecords: 0, days: [
    //   {day: '2023-10-01', records: 0},
    //   {day: '2023-10-02', records: 1},
    //   {day: '2023-10-03', records: 3},
    //   {day: '2023-10-04', records: 0},
    // ]},
    // {month: '2023-11', expanded: false, totalRecords: 0, completedRecords: 0, days: [
    //   {day: '2023-11-01', records: 0},
    //   {day: '2023-11-02', records: 1},
    //   {day: '2023-11-03', records: 3},
    //   {day: '2023-11-04', records: 0},
    // ]},
    // {month: '2023-12', expanded: false, totalRecords: 0, completedRecords: 0, days: [
    //   {day: '2023-12-01', records: 0},
    //   {day: '2023-12-02', records: 1},
    //   {day: '2023-12-03', records: 3},
    //   {day: '2023-12-04', records: 0},
    // ]},
    // {month: '2024-01', expanded: false, totalRecords: 0, completedRecords: 0, days: [
    //   {day: '2024-01-01', records: 0},
    //   {day: '2024-01-02', records: 1},
    //   {day: '2024-01-03', records: 3},
    //   {day: '2024-01-04', records: 0},
    // ]},
    // {month: '2024-02', expanded: false, totalRecords: 0, completedRecords: 0, days: [
    //   {day: '2024-02-01', records: 0},
    //   {day: '2024-02-02', records: 1},
    //   {day: '2024-02-03', records: 3},
    //   {day: '2024-02-04', records: 0},
    // ]},
    // {month: '2024-03', expanded: false, totalRecords: 0, completedRecords: 0, days: [
    //   {day: '2024-03-01', records: 0},
    //   {day: '2024-03-02', records: 1},
    //   {day: '2024-03-03', records: 3},
    //   {day: '2024-03-04', records: 0},
    // ]},
  ]);
  const [migrationModalShow, migrationModalProps] = useSimpleModal({});
  const [selectedMonths, setSelectedMonths] = useState<string[]>([]);
  const [copied, setCopied] = useState(false);

  const [monthOverview, setMonthOverview] = useState<{ month: string; v1: number; v2: number; loading: boolean }[]>([]);

  const refreshMonth = async (month: string, allItems: typeof monthOverview) => {
    const index = allItems.findIndex((item) => item.month === month);
    if (index === -1) return;
    const item = allItems[index];
    try {
      const from = moment.utc(month).startOf('month').format();
      const to = moment.utc(month).startOf('month').add(1, 'month').startOf('month').format();
      const counter = await api.devTools.showStatusLogMigrationDiff({ from, to });
      item.v1 = counter.data.v1;
      item.v2 = counter.data.v2;
      item.loading = false;
      setMonthOverview([...allItems]);
    } catch (err) {
      console.error(`Error while refreshing month ${month}`, err);
      item.loading = false;
      setMonthOverview([...allItems]);
    }
  };

  const reloadOverview = async () => {
    const firstMonth = '2023-08-01';
    const lastMonth = moment.utc().startOf('month').format('YYYY-MM-DD');
    let currentMonth = firstMonth;
    const items: typeof monthOverview = [];
    while (currentMonth <= lastMonth) {
      items.push({ month: currentMonth, v1: 0, v2: 0, loading: true });
      currentMonth = moment.utc(currentMonth).add(1, 'month').format('YYYY-MM-DD');
    }

    const sortedItems = items.sort((a, b) => a.month.localeCompare(b.month));
    setMonthOverview(sortedItems);
    for (const item of sortedItems) {
      await refreshMonth(item.month, sortedItems);
      await sleep(200);
    }
  };

  const deleteMonthInV2 = async (month: string) => {
    const item = monthOverview.find((item) => item.month === month);
    if (!item) return;

    item.loading = true;
    setMonthOverview([...monthOverview]);

    const from = moment.utc(month).startOf('month').format('YYYY-MM-DD');
    const to = moment.utc(month).startOf('month').add(1, 'month').startOf('month').format('YYYY-MM-DD');
    await api.devTools.deleteMigratedItems({ from, to });
    refreshMonth(month, monthOverview);
  };

  // const [mmigrationDiffResp, mmigrationDiffFetching, mmigrationDiffErr, migrationDiffRefresh] = useApi({
  //   call: async () => await api.devTools.showStatusLogMigrationDiff(),
  //   map: (data) => data,
  // });

  // useEffect(() => {
  //   setSelectedMonths([]);
  // }, [mmigrationDiffResp]);

  const prepareDayMigration = async (day: string, log: MigrationMonthLog) => {
    const from = moment.utc(day).startOf('day').format();
    const to = moment.utc(day).startOf('day').add(1, 'day').startOf('day').format();

    const diff = await api.devTools.showStatusLogMigrationDiff({ from, to });
    const v1Cnt = diff.data.v1;
    const v2Cnt = diff.data.v2;
    if (v1Cnt === v2Cnt) {
      log.msg = `INFO: ${day}: v1: ${v1Cnt} / v2 ${v2Cnt} / will skip day`;
      updateMigrationMonthLog(log);
      await sleep(1000);
      return { result: false, v2Cnt: v2Cnt, v1Cnt: v1Cnt };
    }

    log.msg = `WARN: ${day}: v1: ${v1Cnt} / v2 ${v2Cnt} / will delete v2 records`;
    updateMigrationMonthLog(log);

    await api.devTools.deleteMigratedItems({ from, to });
    await sleep(1000);

    return { result: true, v2Cnt: 0, v1Cnt: v1Cnt };
  };

  const migrateMonth = async (log: MigrationMonthLog) => {
    const firstDayOfMonth = moment.utc(log.month).startOf('month');
    const firstDayNextMonth = moment.utc(log.month).endOf('month').startOf('day').add(1, 'day').format();
    let currentDay = firstDayOfMonth.clone();
    log.days = [];
    while (currentDay.isBefore(firstDayNextMonth)) {
      const LIMIT = 100;
      let skip = 0;
      log.days.push({
        day: currentDay.format('YYYY-MM-DD'),
        records: 0,
        recordsV1: 0,
      });
      const day = log.days.find((d) => d.day === currentDay.format('YYYY-MM-DD'))!;

      const prepareResult = await prepareDayMigration(currentDay.format('YYYY-MM-DD'), log);
      day.recordsV1 = prepareResult.v1Cnt;
      if (prepareResult.result) {
        while (true) {
          log.completedRecords = _.sumBy(log.days, (d) => d.records);
          updateMigrationMonthLog(log);

          const from = currentDay.clone().startOf('day').format();
          const to = currentDay.clone().startOf('day').add(1, 'day').startOf('day').format();
          try {
            const resp = await api.devTools.migateStatusLogFor({ limit: LIMIT, skip, from, to });
            day.records += resp.data.records;
            if (resp.data.records < LIMIT) break;
          } catch (err) {
            log.msg = `ERROR: ${currentDay.format('YYYY-MM-DD')} / ${skip}: ${(err as any)?.message}`;
          }
          skip += LIMIT;
        }
      } else {
        day.records = prepareResult.v2Cnt;
      }

      log.completedRecords = _.sumBy(log.days, (d) => d.records);
      updateMigrationMonthLog(log);
      currentDay = currentDay.add(1, 'day');
    }

    log.completedRecords = _.sumBy(log.days, (d) => d.records);
    updateMigrationMonthLog(log);
  };

  const updateMigrationMonthLog = (log: MigrationMonthLog) => {
    setMigrationLog((current) => {
      const index = current.findIndex((item) => item.month === log.month);
      if (index === -1) return current;
      current[index] = log;
      return [...current];
    });
  };

  const migrateSelcetdClicked = async () => {
    if (selectedMonths.length <= 0) return;
    if (monthOverview.length <= 0) return;

    const logEntries: MigrationMonthLog[] = selectedMonths.map((month) => {
      const model = monthOverview.find((item) => item.month === month);
      return {
        days: [],
        completedRecords: 0,
        expanded: false,
        month,
        totalRecords: model?.v1 || 0,
      };
    });

    setMigrationLog(logEntries);
    migrationModalShow({});
    for (const log of logEntries) {
      await migrateMonth(log);
    }
  };

  const onMigrateClicked = async (entry: { month: string; records: number }) => {
    const logEntries: MigrationMonthLog[] = [
      {
        days: [],
        completedRecords: 0,
        expanded: false,
        month: entry.month,
        totalRecords: entry.records,
      },
    ];

    setMigrationLog(logEntries);
    migrationModalShow({});
    migrateMonth(logEntries[0]);
  };

  return (
    <Page>
      <PageHeader>Status Log Migration</PageHeader>
      <PageContent>
        <Box kind={'vflex'} gap='m'>
          <Box kind={'hflex'} gap='m' justify='flex-end'>
            <ButtonV2 size='s' apperance='primary' onClick={() => reloadOverview()}>
              Load Data
            </ButtonV2>
            <Divider kind='v' />
            <ButtonV2 onClick={migrateSelcetdClicked} size='s' apperance='primary' disabled={selectedMonths.length <= 0}>
              Migrate Selected
            </ButtonV2>
          </Box>

          <Box>
            <DataTable
              h='100%'
              records={monthOverview}
              customRow={(entry) => {
                if (entry.loading)
                  return (
                    <>
                      <TableRowSkeleton />
                      <TableRowSkeleton />
                      <TableRowSkeleton />
                      <TableRowSkeleton />
                      <TableRowSkeleton />
                      <TableRowSkeleton />
                    </>
                  );
                else return undefined;
              }}
              layout={[
                {
                  width: '50px',
                  header: {
                    node: () => <></>,
                  },
                  cell: {
                    node: (entry) => (
                      <Box h='100%' kind={'vflex'} justify='center' align='center' w='100%'>
                        <CheckBox
                          isSelected={selectedMonths.includes(entry.month)}
                          onChange={() => {
                            setSelectedMonths((current) => {
                              if (current.includes(entry.month)) return current.filter((item) => item !== entry.month);
                              else return [...current, entry.month];
                            });
                          }}
                          label={<></>}
                        />
                      </Box>
                    ),
                  },
                },
                {
                  header: {
                    node: () => <>Month</>,
                  },
                  cell: {
                    node: (entry) => (
                      <Box h='100%' kind={'vflex'} justify='center'>
                        {prettyMonth(entry.month)}
                      </Box>
                    ),
                  },
                },
                {
                  header: {
                    node: () => <>V1 Count</>,
                  },
                  cell: {
                    node: (entry) => (
                      <Box h='100%' kind={'vflex'} justify='center'>
                        {formatter.formatNumber(entry.v1, 'de', 0)}
                      </Box>
                    ),
                  },
                },
                {
                  header: {
                    node: () => <>V2 Count</>,
                  },
                  cell: {
                    node: (entry) => (
                      <Box h='100%' kind={'vflex'} justify='center'>
                        {formatter.formatNumber(entry.v2, 'de', 0)}
                      </Box>
                    ),
                  },
                },
                {
                  header: {
                    node: () => <>Diff</>,
                  },
                  width: '120px',
                  cell: {
                    node: (entry) => {
                      let fg = getRecordColor(entry);
                      return (
                        <Box h='100%' kind={'vflex'} justify='center'>
                          <DotBadge w='100%' circleColor={fg} kind={'hflex'} justify='space-between'>
                            {formatter.formatNumber(entry.v2 - entry.v1, 'de', 0)}
                          </DotBadge>
                        </Box>
                      );
                    },
                  },
                },
                {
                  width: '340px',
                  header: {
                    node: () => <>Actions</>,
                  },
                  cell: {
                    node: (entry) => {
                      return (
                        <Box kind={'hflex'} justify='flex-end' gap='m'>
                          <ButtonV2 onClick={() => onMigrateClicked({ month: entry.month, records: entry.v1 })} size='s' apperance='primary'>
                            Migrate
                          </ButtonV2>
                          <Divider />
                          <ButtonV2
                            onClick={() => {
                              entry.loading = true;
                              setMonthOverview((current) => [...current]);
                              refreshMonth(entry.month, monthOverview);
                            }}
                            size='s'
                            apperance='secondary'
                          >
                            Refresh
                          </ButtonV2>
                          <Divider />

                          <ButtonV2 onClick={() => deleteMonthInV2(entry.month)} size='s' apperance='danger'>
                            Delete V2
                          </ButtonV2>
                        </Box>
                      );
                    },
                  },
                },
              ]}
            />
          </Box>
        </Box>

        <Modal {...migrationModalProps}>
          {(close) => {
            return (
              <>
                <ModalHeader title={'Migration'} />
                <ModalContent>
                  <Box kind={'vflex'} gap='m'>
                    {migrationLog.map((month) => {
                      return (
                        <Accordion key={month.month} collapsed={!month.expanded}>
                          <AccordionHead>
                            <Box kind={'vflex'} gap='m' flexGrow='1'>
                              <Box kind={'hflex'} gap='m'>
                                <Box flexGrow='1' align='center' kind={'hflex'} gap='l'>
                                  <Box>{prettyMonth(month.month)}</Box>
                                  <Box>
                                    {formatter.formatNumber(month.totalRecords, 'de', 0)} /{formatter.formatNumber(month.completedRecords, 'de', 0)}
                                  </Box>
                                </Box>
                                <Divider />
                                <AccordionExpandBtn
                                  onClick={() => {
                                    setMigrationLog((current) => {
                                      current.find((item) => item.month === month.month)!.expanded = !month.expanded;
                                      return [...current];
                                    });
                                  }}
                                />
                              </Box>
                              {month.msg && (
                                <Box fg='primary-500' fs={'m'}>
                                  {month.msg}
                                </Box>
                              )}
                            </Box>
                          </AccordionHead>
                          <AccordionContent>
                            <Box kind={'vflex'} gap='xs'>
                              {month.days.map((day) => {
                                return (
                                  <Box key={day.day} kind={'hflex'} gap='l'>
                                    <Box>{prettyDay(day.day)}</Box>
                                    <Box>
                                      {formatter.formatNumber(day.recordsV1, 'de', 0)} / {formatter.formatNumber(day.records, 'de', 0)}
                                    </Box>
                                  </Box>
                                );
                              })}
                            </Box>
                          </AccordionContent>
                        </Accordion>
                      );
                    })}
                  </Box>
                </ModalContent>
                <Divider kind='h' />
                <ModalFooter>
                  <ModalCancelButton onClick={() => close(true)} size='m' apperance='primary'>
                    Close
                  </ModalCancelButton>
                  <Divider kind='v' />
                  <ModalAcceptButton
                    onClick={async () => {
                      const data = JSON.stringify(migrationLog, null, 2);
                      console.log('copied', data);
                      await navigator.clipboard.writeText(data);
                      setCopied(true);
                      setTimeout(() => {
                        setCopied(false);
                      }, 2000);
                    }}
                  >
                    {copied ? 'Copied' : 'Copy Log'}
                  </ModalAcceptButton>
                </ModalFooter>
              </>
            );
          }}
        </Modal>
      </PageContent>
    </Page>
  );
}
