import { ReactComponent as BatteryChargingIco } from '../../../assets/battery-charging.svg';
import classNames from 'classnames';
import { ReactComponent as ChargePowerUsed } from '../../../assets/charge-power-used.svg';
import { ReactComponent as ClockIco } from '../../../assets/clock.svg';
import { ReactComponent as ThreeDotsIco } from '../../../assets/dots-three.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import { ReactComponent as ExternalHardwareIco } from '../../../assets/exclamation.svg';
import { ReactComponent as LockIco } from '../../../assets/lock2.svg';
import { ReactComponent as PublicStationsIco } from '../../../assets/public-stations.svg';
import { NodeHierarchyLabelInverted } from '../../../components/org-hierarchy/node-hierarchy-label';
import OutletTypeIcon, { localizePlugType, PlugType } from '../../../components/outlet-type-icon';
import { ChargePoint } from '../../../pages/stations/chargepoint-remote-actions';
import { ChargeBoxDomainStatus, ConnectorDomainStatus, DomainSubStatusForNotAvailable, SharedClientHierarchyNodeDto } from '../../../services/api-client/csp-api';
import { getDomainStatusTitle } from '../../../utils/domain-status';
import useBetterTranslate from '../../../utils/translation-utils';
import ButtonV2 from '../../button';
import Ico, { Circle } from '../../ico';
import Pill from '../../pill';
import Box, { Divider } from '../../utils';
import VerticalList from '../../vertical-list';
import { CHARGEPOINT_DOMAIN_STATUS_COLORS } from '../domain-mappings';
import styles from './chargepoint-list-item.module.scss';

export interface ChargepointListItemModel {
  domainStatus: ConnectorDomainStatus;
  evseId?: string;
  customName?: string;
  chargeBoxId: string;
  connectorId: string;
  nodeCode: string;
  outletType: string;
  chargePointType: string;
  possibleChargingPower: string;
  domainStatusOfStation: ChargeBoxDomainStatus;
  currentLoad: number;
  currentSoc?: number;
  currentLoadUnit: string;
  loadMgmtOfClusterAndStationActive: boolean;
  can: { remoteStartSession: boolean; remoteStopSession: boolean; remoteUnlock: boolean; remoteReserve: boolean; remoteChangeAvailability: boolean; goToSessions: boolean };
  isAssignedToCluster: boolean;
  isReduced: boolean;
  domainSubStatusForNotAvailable: DomainSubStatusForNotAvailable;
  clusterId?: string;
  isExternalHardware: boolean;
  isPublic: boolean;
}

export interface ChargepointListItemProps {
  className?: string;
  item: ChargepointListItemModel;
  allNodes: SharedClientHierarchyNodeDto[];
  sessionsLink: string;
  stationsPath?: string;
  clientCode?: string;
  loadManagementLinkActive?: boolean;
  onClickRemoteActionButton: (ev: React.MouseEvent<Element, MouseEvent>, cp: ChargePoint) => void;
  hasContextIcons: boolean;
  onClickChangeCustomName: () => void;
  hasChangeCustomNamePopup?: boolean;
}

export default function ChargepointListItem(props: ChargepointListItemProps) {
  const { item } = props;
  const { domainStatus } = item;
  const { _t } = useBetterTranslate('chargepoints-list-item');
  // item.isReduced = false;

  function isReserved(cp: ChargepointListItemModel) {
    return cp.domainStatus === 'notAvailable' && cp.domainSubStatusForNotAvailable === 'reserved';
  }

  function isLocked(cp: ChargepointListItemModel) {
    return cp.domainStatus === 'notAvailable' && cp.domainSubStatusForNotAvailable === 'locked';
  }

  return (
    <Box testId='chargepoint' kind={'hflex'} gap='xs' className={classNames(styles.root, props.className)}>
      <Box testId={'chargepoint_status'} className={styles.statusNoch} bg={CHARGEPOINT_DOMAIN_STATUS_COLORS[domainStatus]}></Box>
      <Box fs={'s'} gap='s' kind={'hgrid'} className={styles.contentArea} flexGrow='1'>
        <Box kind={'vflex'} cellSize='1' gap='s'>
          <Box kind={'hflex'} gap='s' align='center'>
            <Box bc={CHARGEPOINT_DOMAIN_STATUS_COLORS[item.domainStatus]} className={styles.domainStatusLbl}>
              {getDomainStatusTitle(item.domainStatus)}
            </Box>
            {item.currentSoc && item.domainStatus === ConnectorDomainStatus.Charging && (
              <>
                <Divider kind='v' />
                <Box kind={'hflex'} gap='xs' align='center' tooltip={_t('The State of Charge can only be displayed for DC chargers, that are configured accordingly')}>
                  <Ico size='24px' file={<BatteryChargingIco />} />
                  <Box>{_t('State of charge')}:</Box>
                  <Box fw='600'>{item.currentSoc.toFixed(0)}%</Box>
                </Box>
              </>
            )}
          </Box>
          <Box ellipses fs={'m'} fw='700' kind={'hflex'} gap='s' align='center'>
            <Box testId='chargepoint_name' fg={item.customName !== item.evseId ? 'primary-neutral-100' : 'primary-neutral-085'}>
              {item.customName || item.chargeBoxId}
            </Box>
            <VerticalList
              gap='s'
              divider
              items={[
                props.hasChangeCustomNamePopup && (
                  <ButtonV2 dataCy='chargepoint_name_edit' onClick={props.onClickChangeCustomName} className={styles.editCustomName} apperance='icon'>
                    <Ico file={<EditIcon />} />
                  </ButtonV2>
                ),
                item.isExternalHardware && <Ico fill='primary-neutral-080' size='16px' file={<ExternalHardwareIco />} />,
                item.isPublic && <Ico testId='chargepoint_is_public' fill='primary-neutral-080' size='16px' file={<PublicStationsIco />} />,
              ]}
            />
          </Box>
          <NodeHierarchyLabelInverted allNodes={props.allNodes} code={item.nodeCode} hideArea={true} hideClientRoot={true} />
        </Box>
        <Box kind={'vflex'} cellSize='1' gap='s'>
          <Box testId='chargepoint_original_name' fw='700'>
            {_t('EVSE-ID')}: {item.evseId || _t('EVSE ID unbekannt')}
          </Box>
          {item.domainStatus === ConnectorDomainStatus.Charging && (
            <Box kind={'hflex'} align='center' gap='xs'>
              <Box
                className={styles.energyTag}
                kind={'hflex'}
                align='center'
                justify='center'
                gap='xs'
                radius='8px'
                tooltip={item.isReduced ? _t('Aktuelle Ladeleistung reduced') : _t('Aktuelle Ladeleistung not reduced')}
                bg={item.isReduced ? 'dataviz-brown-naples-yellow' : 'dataviz-blue-pale-cyan'}
              >
                <Ico size='18px' file={<ChargePowerUsed />} />
                <span>{`${item.currentLoad?.toFixed(0) || 0} ${item.currentLoadUnit || _t('kW')}`}</span>
              </Box>
              {item.possibleChargingPower && (
                <span>
                  {_t('of max.')} {item.possibleChargingPower}
                </span>
              )}
            </Box>
          )}
          {item.isAssignedToCluster ? (
            <Box kind={'hflex'} gap='xs'>
              <Box fw='700'>{_t('Lastmanagement')}:</Box>
              {item.loadMgmtOfClusterAndStationActive && (
                <Box className={styles.lmStatus} fw='700' kind={'hflex'} align='center' gap='xs'>
                  <Circle color='primary-500' size='8px' />
                  {_t('Aktiviert')}
                </Box>
              )}
              {!item.loadMgmtOfClusterAndStationActive && (
                <Box className={styles.lmStatus} kind={'hflex'} align='center' gap='xs'>
                  <Circle borderColor='primary-500' color='brand-neutral-000' size='8px' />
                  {_t('Deaktiviert')}
                </Box>
              )}
            </Box>
          ) : (
            <></>
          )}
        </Box>

        <Box kind={'hflex'} cellSize='1' gap='m'>
          <Box kind={'vflex'} justify='flex-end' flexGrow='1'>
            <Box kind={'vflex'} gap={'xs'} w='fit-content' bc='brand-neutral-200' radius='8px' pad={'200'}>
              <Box kind={'hflex'} gap='xs' align='center'>
                <Ico size='24px' file={<OutletTypeIcon type={item.outletType as PlugType} color='black' />} />
                <span>{localizePlugType(item.outletType) || _t('Unbekannt')}</span>
              </Box>
              {item.chargePointType && (
                <Box>
                  {item.chargePointType || ''}
                  {item.possibleChargingPower && ' max. '}
                  {item.possibleChargingPower}
                </Box>
              )}
            </Box>
          </Box>
          <Box kind={'vflex'} justify='flex-end' gap='m'>
            {isReserved(item) && (
              <Pill apperance='primary-neutral-030' tooltip={_t('Ladepunkt ist reserviert')}>
                <Box pad={['0', '300']}>
                  <Ico size='16px' file={<ClockIco />} />
                </Box>
              </Pill>
            )}

            {isLocked(item) && (
              <Pill apperance='primary-neutral-030' tooltip={_t('Ladepunkt ist gesperrt')}>
                <Box pad={['0', '300']}>
                  <Ico size='16px' file={<LockIco />} />
                </Box>
              </Pill>
            )}
          </Box>
        </Box>
        <Box kind={'vflex'} cellSize='1'>
          <Box kind={'hflex'} gap='m' justify='flex-end'>
            <ButtonV2 dataCy='chargepoint_sessions' apperance='secondary' disabled={!item.can.goToSessions} href={props.sessionsLink}>
              {_t('Charging Records')}
            </ButtonV2>

            <Divider />
            <ButtonV2
              dataCy='chargepoint_submenu'
              onClick={(ev: React.MouseEvent<Element, MouseEvent>) => {
                props.onClickRemoteActionButton(ev, item);
              }}
              disabled={!props.hasContextIcons}
              tooltip={
                !props.hasContextIcons
                  ? _t('Fernsteuerung nicht möglich: Die Ladestation ist entweder offline oder nicht in Betrieb oder Sie verfügen nicht über die notwendige Berechtigung.')
                  : ''
              }
              apperance='icon'
            >
              <Ico size='20px' rotate={90} file={<ThreeDotsIco />} />
            </ButtonV2>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
