import { ReactNode } from 'react';
import { translate } from '../../i18n';
import { ReactComponent as FlagDeIco } from '../../assets/flag-de.svg';
import { ReactComponent as FlagEnIco } from '../../assets/flag-en.svg';
import { ReactComponent as FlagFrIco } from '../../assets/flag-fr.svg';
import {
  ChargeBoxDomainStatus,
  ChargeBoxOrConnectorStatus,
  ConnectorDomainStatus,
  DocumentType,
  RateServiceType,
  RoleType,
  SessionStatus,
} from '../../services/api-client/csp-api';
import { Colors, BoxProps } from '../utils';

const _t = translate('common-translations');

export function rateTypeTranslation(rateType: RateServiceType): string {
  if (rateType === 'HOME') return _t('Home');
  if (rateType === 'EMPLOYEE') return _t('Employee');
  if (rateType === 'PUBLIC') return _t('Public');
  if (rateType === 'WORK') return _t('Work');
  if (rateType === 'UNDEFINED') return _t('Unbekannt');

  return _t('Unbekannt');
}

export function rateTypeColor(rateType: RateServiceType): Colors {
  if (rateType === 'HOME') return 'blue-blue-jeans'; // todo, set correct
  if (rateType === 'EMPLOYEE') return 'secondary-teal';
  if (rateType === 'PUBLIC') return 'dataviz-blue-medium-persian';
  if (rateType === 'WORK') return 'dataviz-green-light-green';
  if (rateType === 'UNDEFINED') return 'functional-disabled-200';

  return 'functional-disabled-200';
}

export function languageNameTranslation(language: string): string {
  const translations: Record<string, string> = {
    en: _t('English'),
    fr: _t('French'),
    de: _t('German'),
  };

  return translations[language] || language;
}

export function documentTypeTransaltion(documentType: DocumentType): string {
  const map: Record<DocumentType, string> = {
    installation: _t('Installation'),
    maintenance: _t('Maintenance'),
    service: _t('Service'),
  };

  return map[documentType] || _t('Unknown');
}

export function documentTypeColors(documentType: DocumentType): Colors {
  const map: Record<DocumentType, Colors> = {
    installation: 'todo-blue-light',
    maintenance: 'todo-green-light',
    service: 'todo-yellow-light',
  };

  return map[documentType] || 'functional-disabled-200';
}

export function languageIcon(language: string): ReactNode {
  const flags: Record<string, ReactNode> = {
    en: <FlagEnIco />,
    fr: <FlagFrIco />,
    de: <FlagDeIco />,
  };

  return flags[language] || null;
}

export function roleTypeColor(roleType: RoleType): Colors {
  if (roleType === RoleType.Anchor) return 'functional-error-full';
  if (roleType === RoleType.Standard) return 'secondary-teal';
  if (roleType === RoleType.Root) return 'primary-neutral-090';
  if (roleType === RoleType.Individual) return 'dataviz-purple-middle-blue-purple';
  if (roleType === RoleType.System) return 'functional-error-full';

  return 'functional-error-full';
}

export function roleTypeTranslation(roleType: RoleType): string {
  if (roleType === RoleType.Anchor) return _t('Anchor');
  if (roleType === RoleType.Standard) return _t('Standard');
  if (roleType === RoleType.Root) return _t('Root');
  if (roleType === RoleType.Individual) return _t('Individual');
  if (roleType === RoleType.System) return _t('System');

  return _t('Unknown');
}

export const STATION_DOMAIN_STATUS_COLORS: Record<ChargeBoxDomainStatus, Colors> = {
  failure: 'functional-error-full',
  offline: 'brown-gold-metallic',
  notInOperation: 'primary-neutral-090',
  online: 'green-kelly-green',
};

export const CHARGEPOINT_DOMAIN_STATUS_COLORS: Record<ConnectorDomainStatus, Colors> = {
  available: 'secondary-teal',
  charging: 'blue-blue-jeans',
  occupied: 'red-light-salmon',
  failure: 'functional-error-full',
  notAvailable: 'primary-neutral-090',
  unknown: 'functional-disabled-200',
};

// export const ROLE_

export const STATION_OR_CHARGEPOINT_DOMAIN_STATUS_COLORS: Record<ChargeBoxOrConnectorStatus, Colors> = {
  chargeBoxOnline: STATION_DOMAIN_STATUS_COLORS['online'],
  chargeBoxOffline: STATION_DOMAIN_STATUS_COLORS['offline'],
  chargeBoxFailure: STATION_DOMAIN_STATUS_COLORS['failure'],
  chargeBoxNotInOperation: CHARGEPOINT_DOMAIN_STATUS_COLORS['notAvailable'],
  connectorAvailable: CHARGEPOINT_DOMAIN_STATUS_COLORS['available'],
  connectorCharging: CHARGEPOINT_DOMAIN_STATUS_COLORS['charging'],
  connectorNotAvailableReserved: CHARGEPOINT_DOMAIN_STATUS_COLORS['notAvailable'],
  connectorNotAvailableLocked: CHARGEPOINT_DOMAIN_STATUS_COLORS['notAvailable'],
  connectorNotAvailableNone: CHARGEPOINT_DOMAIN_STATUS_COLORS['notAvailable'],
  connectorFailure: CHARGEPOINT_DOMAIN_STATUS_COLORS['failure'],
  connectorUnknown: CHARGEPOINT_DOMAIN_STATUS_COLORS['unknown'],
  connectorOccupied: CHARGEPOINT_DOMAIN_STATUS_COLORS['occupied'],
};

export function stationOrChargepointStatusTranslation(status: ChargeBoxOrConnectorStatus): string {
  const map: Record<ChargeBoxOrConnectorStatus, string> = {
    chargeBoxOnline: _t('Online'),
    chargeBoxOffline: _t('Offline'),
    chargeBoxFailure: _t('Fehlerhaft'),
    connectorAvailable: _t('Verfügbar'),
    connectorCharging: _t('Laden'),
    connectorOccupied: _t('Besetzt'),
    chargeBoxNotInOperation: _t('Nicht verfügbar'),
    connectorNotAvailableReserved: _t('Nicht verfügbar (Reserviert)'),
    connectorNotAvailableLocked: _t('Nicht verfügbar (Gesperrt)'),
    connectorNotAvailableNone: 'Nicht verfügbar (Sonstiges)',
    connectorFailure: _t('Fehlerhaft'),
    connectorUnknown: _t('Unbekannt'),
  };

  return map[status];
}

export function sessionStatusTranslation(status: SessionStatus): string {
  const map: Record<SessionStatus, string> = {
    charging: _t('Charging'),
    closed: _t('Closed'),
    occupied: _t('Occupied'),
  };

  return map[status];
}
export const SESSION_STATUS_COLORS: Record<SessionStatus, Colors> = {
  charging: 'blue-blue-jeans',
  closed: 'brand-neutral-300',
  occupied: 'red-light-salmon',
};
