import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import DataTableV2, { sortChecker, sortHandler } from '../../components-v2/data-table/data-table';
import OrgHierarchyPicker from '../../components/org-hierarchy/org-hierarchy-picker';
import { useApi } from '../../hooks/useApi';
import { useBetterNavigate } from '../../hooks/useBetterNavigate';
import { useMakePagingFilterParams, usePageParams } from '../../hooks/usePageParams';
import api from '../../services/api';
import { ChargingCardListItemDto, ChargingCardsEntityFilterDto, RateServiceType } from '../../services/api-client/csp-api';
import { endOfDay, formateByResolution, getTimezone, startOfDay } from '../../utils/date';
import { formatEnergy, formatKWhEnergy } from '../../utils/format';

import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as InfoIcon } from '../../assets/info-v2.svg';
import Badge, { DotBadge } from '../../components-v2/badge/badge';
import { EnergyHistoryStackBarChart, EnergyStackBarChart, EnergyTotalsList, SessionsTotalsList } from '../../components-v2/business-components/common-charts/common-charts';
import { rateTypeColor, rateTypeTranslation } from '../../components-v2/business-components/domain-mappings';
import { SmartSuggest } from '../../components-v2/business-components/suggestions/suggestions';
import ButtonV2, { ButtonToggle, ButtonToggles } from '../../components-v2/button';
import { DateRangeChooser } from '../../components-v2/date-range';
import FilterToggle from '../../components-v2/filter-toggle/filter-toggle';
import Ico from '../../components-v2/ico';
import { FormContainerV2 } from '../../components-v2/input';
import { SelectedTag, SelectedTagArea, TagSuggestion, useTagSuggestion } from '../../components-v2/tag-suggestion/tag-suggestion';
import Box, { ChartSkeleton, contrastColor, Divider, HugeListItemSkeleton, Spaces2, StackedSkeleton } from '../../components-v2/utils';
import { ApiBasedContent } from '../../components/page-layout';
import { useAuth } from '../../utils/AuthProvider';
import { createClientSearchProps } from '../../utils/node-picker-client-search-props';
import { dateRangeValidator, hierarchyValidator, pagingValidator, validateArrayOfString, validateString } from '../../utils/queryParamValidators';
import { checkIfOneElementIsEqual } from '../../utils/tools';
import useBetterTranslate from '../../utils/translation-utils';
import uniqueLineIdentifier from '../../utils/unique-line-ident';
// import SetCustomNamePopup from '../stations/custom-name-popup';
import { useModal } from '../../components-v2/modal/modal';
import { Page, PageContent, PageHeader, PageTitle } from '../../components-v2/page/page';
import Pagingation from '../../components-v2/pagination/pagination';
import { SwitchToggle } from '../../components-v2/switch-toggle';
import { CustomNamePopup, CustomNamePopupArgs } from '../stations/custom-name-popup';
import styles from './charging-cards-list-page.module.scss';
import { CommentPopup, CommentPopupArgs } from './comment-popup';

const IMPLICIT_FILTERS = ['skip', 'limit', 'clientCode', 'nodes'];

export default function ChargingCardsListPage() {
  const { _t } = useBetterTranslate('charging-cards-list-page');
  const { user } = useAuth();

  const MAX_DATE = useMemo(() => moment().add(1, 'day'), []);
  const MIN_DATE = useMemo(() => moment().subtract(2, 'year'), []);
  const DATE_FORMAT = 'YYYY-MM-DD';
  const SERVICE_TYPE_ALLOWED_VALUES = [RateServiceType.WORK, RateServiceType.EMPLOYEE, RateServiceType.HOME, RateServiceType.UNDEFINED];
  const SORT_BY_VALUES = ['active', 'number', 'totalEnergy', 'ownerName', 'rateServiceType', 'rateSlug', 'comment', 'sessionServiceTypes', 'label'];
  const languageCode = user?.preferences.languageCode || 'de';

  const navigate = useBetterNavigate<FilterParameters>();

  type FilterParameters = {
    from?: string;
    to?: string;
    skip?: number | null;
    limit?: number | null;
    serviceType?: string[];
    active?: string;
    onGoing?: string;
    entity?: ChargingCardsEntityFilterDto[];
    sortBy?: string;
    sortDesc?: string;
    clientCode?: string;
    nodes?: string[];
  };

  const cardsValidators = {
    serviceType: validateArrayOfString(SERVICE_TYPE_ALLOWED_VALUES),
    sortBy: validateString(SORT_BY_VALUES),
  };
  const validators = { ...dateRangeValidator(MIN_DATE, MAX_DATE), ...pagingValidator, ...cardsValidators, ...hierarchyValidator };

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>(
    {
      from: moment().subtract(30, 'day').format(DATE_FORMAT),
      to: moment().format(DATE_FORMAT),
    },
    validators
  );

  const [filterSectionExpanded, setFilterSectionExpanded] = useState(true);
  const clearFilters = () => {
    const changeParams: FilterParameters = {};
    for (const [k] of Object.entries(filterParams)) {
      if (IMPLICIT_FILTERS.includes(k)) continue;
      (changeParams as any)[k as any] = undefined;
    }

    setFilterParams(changeParams);
  };

  const [toggleActivation, setToggleActivation] = useState(false);

  const searchTags = async (txt: string) => {
    if (!cardsResp?.clientContext?.code) return [];
    const entityFilterRes = await api.chargingCards.searchFilters({ q: txt, clientCode: cardsResp.clientContext.code }, { cancelToken: uniqueLineIdentifier() });
    return entityFilterRes.data;
  };
  const suggestion = useTagSuggestion({
    fetchOptions: async (val) => searchTags(val),
    createOption: (val) => <SmartSuggest label={val.title || ''} type={val.type} />,
    filterDuplicates: true,
    provideIdentifier: (val) => val.id,
    createSelectedTag: (val, onRemove) => <SelectedTag onRemove={onRemove}>{val.title}</SelectedTag>,
  });

  const toggleServiceFilter = (status: RateServiceType) => {
    let allActive = [...(filterParams.serviceType || [])];
    if (allActive.includes(status)) {
      allActive = allActive.filter((item) => item !== status);
    } else {
      allActive.push(status);
    }

    if (allActive.length <= 0) setFilterParams({ serviceType: undefined });
    else setFilterParams({ serviceType: allActive });
  };
  const setFilterParams = useMakePagingFilterParams(_setInnerFilterParams);

  const entityFilters = suggestion.selectedItems;
  useEffect(() => {
    setFilterParams({ entity: entityFilters });
  }, [entityFilters, setFilterParams]);

  const [cardsResp, cardsFetching, cardsApiErr, cardsRespRefresh] = useApi(
    {
      call: async (
        clientCode?: string,
        hierarchyNodeCodes?: string[],
        fromDate?: string,
        toDate?: string,
        skip?: number | null,
        limit?: number | null,
        serviceTypes?: any,
        active?: string,
        onGoing?: string,
        entities?: ChargingCardsEntityFilterDto[],
        sortBy?: any,
        sortDesc?: string
      ) => {
        if (!clientCode) return undefined;
        const resp = await api.chargingCards.list(
          {
            client: clientCode,
            hierarchyNodeCodes: hierarchyNodeCodes || [clientCode],
            limit: Number(limit || 20),
            skip: Number(skip || 0),
            from: startOfDay(fromDate),
            to: endOfDay(toDate),
            serviceTypes,
            active: active ? active === '1' : undefined,
            entityFilter: entities || undefined,
            onGoing: onGoing ? onGoing === '1' : undefined,
            sortBy,
            sortDesc: sortDesc === '1',
          },
          { cancelToken: uniqueLineIdentifier() }
        );

        return resp;
      },
      map: (data) => {
        return data;
      },
    },
    filterParams.clientCode,
    filterParams.nodes,
    filterParams.from,
    filterParams.to,
    filterParams.skip,
    filterParams.limit,
    filterParams.serviceType,
    filterParams.active,
    filterParams.onGoing,
    filterParams.entity,
    filterParams.sortBy,
    filterParams.sortDesc
  );

  const [energyResp, energyRespFetching, energyRespErr] = useApi(
    {
      call: async (clientCode?: string, hierarchyNodeCodes?: string[], fromDate?: string, toDate?: string) => {
        if (!clientCode) return undefined;

        const resp = await api.chargingCards.energyConsumption(
          {
            client: clientCode,
            hierarchyNodeCodes: hierarchyNodeCodes || [clientCode],
            from: startOfDay(fromDate),
            to: endOfDay(toDate),
            timezone: getTimezone(),
          },
          { cancelToken: uniqueLineIdentifier() }
        );
        return resp;
      },
      map: (data) => {
        return data;
      },
    },
    filterParams.clientCode,
    filterParams.nodes,
    filterParams.from,
    filterParams.to
  );

  // probably not needed?
  // const getSessionsPageLink = (sessionsPagePrefix: string, filterParameters: FilterParameters, number: string) => {
  //   const baseUrl = `${sessionsPagePrefix}-charging-sessions/${filterParameters.clientCode}`;
  //   const query = createQueryString({ from: filterParameters.from, to: filterParameters.to, entity: [{ id: number, type: 'card' }], sortBy: 'startTime', sortDesc: 1 });
  //   return `/${baseUrl}?${query}`;
  // };

  const [showBadgeCustomName, badgeCustomNameProps] = useModal<string, false, CustomNamePopupArgs>({});
  const [showBadgeComment, badgeCommentProps] = useModal<string, false, CommentPopupArgs>({});

  const canBadgeBeEdited = (record: ChargingCardListItemDto) => {
    return cardsResp?.can?.clientCardWorkEdit && record.rateServiceType === RateServiceType.WORK && !checkIfOneElementIsEqual(record.rateSlug, 'leasu');
  };

  return (
    <Page>
      <PageHeader>
        <PageTitle>{_t('Charging Badges')}</PageTitle>
      </PageHeader>
      <PageContent>
        <OrgHierarchyPicker
          selectMode='node'
          clientContext={cardsResp?.clientContext}
          onNodeSelected={(clientCode, selectedCodes) => {
            if (clientCode !== cardsResp?.clientContext?.code) {
              navigate(`/charging-cards/${clientCode}`, { nodes: selectedCodes });
              window.location.reload();
            } else {
              setFilterParams({ nodes: selectedCodes });
            }
          }}
          {...createClientSearchProps(cardsResp?.clientContext)}
          selectedNodes={filterParams.nodes}
        />

        <ApiBasedContent
          resp={energyResp}
          err={energyRespErr}
          fetching={energyRespFetching}
          placeholder={() => (
            <Box kind='hflex' gap='m'>
              <ChartSkeleton />
              <ChartSkeleton />
            </Box>
          )}
        >
          {(energyResp) => {
            let xLabel = '';
            if (energyResp.resolution && energyResp.entries && energyResp.entries.length > 1) {
              const start = formateByResolution(energyResp.entries[0].date, languageCode, energyResp.resolution);
              const end = formateByResolution(energyResp.entries[energyResp.entries.length - 1].date, languageCode, energyResp.resolution);
              xLabel = `${start} - ${end}`;
            } else if (energyResp.resolution && energyResp.entries && energyResp.entries.length === 1) {
              xLabel = formateByResolution(energyResp.entries[0].date, languageCode, energyResp.resolution);
            }

            return (
              <Box className={styles.charts} kind={'hgrid'} gap='m'>
                <Box kind='vflex' gap='m' cellSize='5'>
                  <Box fs={'l'} fw='700' fg='primary-neutral-085'>
                    {_t('Number of badges')}: {energyResp.totalCardsCount}
                  </Box>
                  <Divider kind='h' />
                  <Box kind={'hflex'} gap='m'>
                    <Box flexGrow='1' kind={'vflex'}>
                      <SessionsTotalsList
                        customNodeTop={
                          <Box kind={'vflex'} gap='s'>
                            <Box fw='400' kind={'hflex'} justify='space-between'>
                              <Box>{_t('Total')}</Box>
                              <Box>{energyResp.sessionsCount.totalCount}</Box>
                            </Box>
                            <Divider kind='h' />
                            <Box fw='400'>{_t('Used services')}</Box>
                          </Box>
                        }
                        // total={{ value: energyResp.count.totalCount }}
                        work={
                          !energyResp.allowedServiceTypes.includes(RateServiceType.WORK)
                            ? undefined
                            : { value: energyResp.sessionsCount.workCount, onClick: () => setFilterParams({ serviceType: [RateServiceType.WORK] }) }
                        }
                        home={
                          !energyResp.allowedServiceTypes.includes(RateServiceType.HOME)
                            ? undefined
                            : { value: energyResp.sessionsCount.homeCount, onClick: () => setFilterParams({ serviceType: [RateServiceType.HOME] }) }
                        }
                        employee={
                          !energyResp.allowedServiceTypes.includes(RateServiceType.EMPLOYEE)
                            ? undefined
                            : { value: energyResp.sessionsCount.employeeCount, onClick: () => setFilterParams({ serviceType: [RateServiceType.EMPLOYEE] }) }
                        }
                        public={
                          !energyResp.allowedServiceTypes.includes(RateServiceType.PUBLIC)
                            ? undefined
                            : { value: energyResp.sessionsCount.publicCount, onClick: () => setFilterParams({ serviceType: [RateServiceType.PUBLIC] }) }
                        }
                        unknown={
                          !energyResp.allowedServiceTypes.includes(RateServiceType.UNDEFINED)
                            ? undefined
                            : { value: energyResp.sessionsCount.undefinedCount, onClick: () => setFilterParams({ serviceType: [RateServiceType.UNDEFINED] }) }
                        }
                      />
                    </Box>
                    <Divider key={'v'} />
                    <Box flexGrow='1'>
                      <EnergyTotalsList
                        customNodeTop={
                          <Box kind={'vflex'} gap='s'>
                            <Box fw='400' kind={'hflex'} justify='space-between'>
                              <Box>{_t('Total')}</Box>
                              <Box>{`${formatKWhEnergy(energyResp.sessionsSum.totalEnergy.value, languageCode, 0, 0)}`}</Box>
                            </Box>
                            <Divider kind='h' />
                            <Box fw='400'>{_t('Used services')}</Box>
                          </Box>
                        }
                        // total={{ value: energyResp.sum.totalEnergy.value }}
                        work={
                          !energyResp.allowedServiceTypes.includes(RateServiceType.WORK)
                            ? undefined
                            : { ...energyResp.sessionsSum.workEnergy, onClick: () => setFilterParams({ serviceType: [RateServiceType.WORK] }) }
                        }
                        home={
                          !energyResp.allowedServiceTypes.includes(RateServiceType.HOME)
                            ? undefined
                            : { ...energyResp.sessionsSum.homeEnergy, onClick: () => setFilterParams({ serviceType: [RateServiceType.HOME] }) }
                        }
                        employee={
                          !energyResp.allowedServiceTypes.includes(RateServiceType.EMPLOYEE)
                            ? undefined
                            : { ...energyResp.sessionsSum.employeeEnergy, onClick: () => setFilterParams({ serviceType: [RateServiceType.EMPLOYEE] }) }
                        }
                        public={
                          !energyResp.allowedServiceTypes.includes(RateServiceType.PUBLIC)
                            ? undefined
                            : { ...energyResp.sessionsSum.publicEnergy, onClick: () => setFilterParams({ serviceType: [RateServiceType.PUBLIC] }) }
                        }
                        unknown={
                          !energyResp.allowedServiceTypes.includes(RateServiceType.UNDEFINED)
                            ? undefined
                            : { ...energyResp.sessionsSum.undefinedEnergy, onClick: () => setFilterParams({ serviceType: [RateServiceType.UNDEFINED] }) }
                        }
                      />
                    </Box>
                  </Box>
                </Box>
                <Box kind={'vflex'} cellSize='7' gap='m'>
                  <Box kind={'hflex'} justify='flex-end'>
                    <DateRangeChooser
                      minDate={MIN_DATE.toDate()}
                      maxDate={MAX_DATE.toDate()}
                      range={filterParams.from && filterParams.to ? { startDate: filterParams.from, endDate: filterParams.to } : undefined}
                      onChange={(range) => {
                        if (range) setFilterParams({ from: range.startDate, to: range.endDate });
                      }}
                    />
                  </Box>

                  <EnergyStackBarChart
                    work={!energyResp.allowedServiceTypes.includes(RateServiceType.WORK) ? undefined : energyResp.sessionsSum.workEnergy.percentage}
                    home={!energyResp.allowedServiceTypes.includes(RateServiceType.HOME) ? undefined : energyResp.sessionsSum.homeEnergy.percentage}
                    employee={!energyResp.allowedServiceTypes.includes(RateServiceType.EMPLOYEE) ? undefined : energyResp.sessionsSum.employeeEnergy.percentage}
                    public={!energyResp.allowedServiceTypes.includes(RateServiceType.PUBLIC) ? undefined : energyResp.sessionsSum.publicEnergy.percentage}
                    unknown={!energyResp.allowedServiceTypes.includes(RateServiceType.UNDEFINED) ? undefined : energyResp.sessionsSum.undefinedEnergy.percentage}
                  />

                  <EnergyHistoryStackBarChart
                    resolution={energyResp.resolution}
                    xAxisLbl={xLabel}
                    work={!energyResp.allowedServiceTypes.includes(RateServiceType.WORK) ? undefined : energyResp.entries.map((e) => ({ xKey: e.date, y: e.workEnergy }))}
                    home={!energyResp.allowedServiceTypes.includes(RateServiceType.HOME) ? undefined : energyResp.entries.map((e) => ({ xKey: e.date, y: e.homeEnergy }))}
                    employee={
                      !energyResp.allowedServiceTypes.includes(RateServiceType.EMPLOYEE) ? undefined : energyResp.entries.map((e) => ({ xKey: e.date, y: e.employeeEnergy }))
                    }
                    public={!energyResp.allowedServiceTypes.includes(RateServiceType.PUBLIC) ? undefined : energyResp.entries.map((e) => ({ xKey: e.date, y: e.publicEnergy }))}
                    unknown={
                      !energyResp.allowedServiceTypes.includes(RateServiceType.UNDEFINED) ? undefined : energyResp.entries.map((e) => ({ xKey: e.date, y: e.undefinedEnergy }))
                    }
                  />
                </Box>
              </Box>
            );
          }}
        </ApiBasedContent>

        <ApiBasedContent err={cardsApiErr} fetching={cardsFetching} resp={cardsResp} placeholder={() => <StackedSkeleton skeleton={() => <HugeListItemSkeleton />} />}>
          {(cardsResp) => {
            const cellPadding: Spaces2[] = ['200', '0', '200', '0'];
            return (
              <Box kind={'vflex'} gap='m'>
                <Box kind={'vflex'} gap='m' className={styles.tableContainer}>
                  <Box kind={'vflex'} gap='m' pad={'500'}>
                    <Box kind={'hflex'} align='center' gap='m'>
                      <FormContainerV2 dataCy={{ label: 'search' }}>
                        <TagSuggestion {...suggestion.suggestorProps} />
                      </FormContainerV2>

                      <Box kind={'hflex'} justify='flex-end' flexGrow='1' gap='m'>
                        <Divider kind='v' />
                        <FilterToggle onClear={clearFilters} showClear={filterSectionExpanded} toggleExpand={() => setFilterSectionExpanded((current) => !current)} />
                      </Box>
                    </Box>

                    {filterSectionExpanded && (
                      <Box kind={'vflex'} gap='m'>
                        <Box kind={'hflex'} justify='flex-end' gap='m'>
                          <ButtonToggles size='xs'>
                            <ButtonToggle
                              dataCy={`active`}
                              toggled={filterParams.active === '1'}
                              onClick={() => {
                                const active = filterParams.active === '1' ? undefined : '1';
                                setFilterParams({ active });
                              }}
                              circle={{ color: 'primary-500' }}
                            >
                              {_t('Active')}
                            </ButtonToggle>

                            <ButtonToggle
                              dataCy={`inactive`}
                              toggled={filterParams.active === '0'}
                              onClick={() => {
                                const active = filterParams.active === '0' ? undefined : '0';
                                setFilterParams({ active });
                              }}
                              circle={{ color: 'brand-neutral-300' }}
                            >
                              {_t('Inactive')}
                            </ButtonToggle>
                          </ButtonToggles>

                          <Divider kind='v' />

                          <ButtonToggles size='xs'>
                            <ButtonToggle
                              dataCy={`charging`}
                              toggled={filterParams.onGoing === '1'}
                              onClick={() => {
                                const onGoing = filterParams.onGoing === '1' ? undefined : '1';
                                setFilterParams({ onGoing });
                              }}
                              circle={{ color: 'blue-blue-jeans' }}
                            >
                              {_t('Charging')}
                            </ButtonToggle>
                          </ButtonToggles>
                          <Divider kind='v' />

                          {cardsResp.allowedServiceTypes.length > 0 && (
                            <ButtonToggles size='xs'>
                              {cardsResp.allowedServiceTypes.map((enumValue) => {
                                return (
                                  <ButtonToggle
                                    dataCy={`${enumValue}`}
                                    key={enumValue}
                                    toggled={filterParams.serviceType?.includes(enumValue)}
                                    onClick={() => toggleServiceFilter(enumValue)}
                                    circle={{ color: rateTypeColor(enumValue) }}
                                  >
                                    {rateTypeTranslation(enumValue)}
                                  </ButtonToggle>
                                );
                              })}
                            </ButtonToggles>
                          )}
                        </Box>

                        <SelectedTagArea {...suggestion.selectedTagAreaProps} />
                      </Box>
                    )}
                  </Box>

                  <DataTableV2
                    records={cardsResp.cards}
                    layout={[
                      {
                        width: '1fr',
                        header: {
                          padding: ['200', '200', '200', '400'],
                          onSort: sortHandler('active', setFilterParams),
                          sorting: sortChecker('active', filterParams),
                          node: () => (
                            <Box fw='800' kind={'vflex'} gap='xxs' data-cy={'bdge_status'}>
                              <Box>{_t('Badge')}</Box>
                              <Box>{_t('State')}</Box>
                            </Box>
                          ),
                        },
                        subHeader: {
                          node: (records) => {
                            return (
                              cardsResp?.can?.clientCardWorkEdit && (
                                <Box kind='hflex' justify='flex-start' tooltip={_t('Switch to activate/deactivate badges')}>
                                  <button className={styles.toggleBtn} type='button' onClick={() => setToggleActivation(!toggleActivation)}>
                                    <Ico file={<EditIcon />} fill='brand-neutral-300' />
                                    <SwitchToggle checked={toggleActivation} />
                                  </button>
                                </Box>
                              )
                            );
                          },
                        },
                        cell: {
                          padding: ['0', '0', '0', '0'],
                          node: (record) => {
                            let content;
                            if (toggleActivation) {
                              if (canBadgeBeEdited(record)) {
                                content = (
                                  <Box kind='vflex' justify='flex-start' fg='brand-neutral-900'>
                                    {record.active ? _t('Active') : _t('Inactive')}
                                    <SwitchToggle
                                      checked={record.active}
                                      onChange={async () => {
                                        await api.chargingCards.changeActivation({
                                          badgeNumber: record.extId,
                                          client: filterParams.clientCode!,
                                          active: !record.active,
                                          hierarchyNodeCodes: filterParams.nodes || [filterParams.clientCode!],
                                        });
                                        cardsRespRefresh();
                                      }}
                                    />
                                  </Box>
                                );
                              } else {
                                if (cardsResp?.can?.clientCardWorkEdit) {
                                  content = (
                                    <div title={_t('Only states from badges with the service type WORK can be edited. Badges provided by leasing partners cannot be edited.')}>
                                      <Ico file={<InfoIcon />} />
                                    </div>
                                  );
                                }
                              }
                            } else {
                              content = record.active ? (
                                <DotBadge circleColor={'primary-500'}>{_t('Active')}</DotBadge>
                              ) : (
                                <DotBadge circleColor='brand-neutral-000' circleBorderColor={'primary-500'}>
                                  {_t('Inactive')}
                                </DotBadge>
                              );
                            }
                            return (
                              <Box className={styles.badgeStatusWrapper} kind={'hflex'} h='100%' data-cy={'el_status'}>
                                <Box className={record.active ? styles.stateActive : styles.stateInactive}></Box>
                                <Box h='100%' pad={['400', '0', '200', '200']} kind={'vflex'} justify='flex-start' testId='el_status' fg='primary-500'>
                                  {content}
                                </Box>
                              </Box>
                            );
                          },
                        },
                      },
                      {
                        width: '2fr',
                        header: {
                          onSort: sortHandler('number', setFilterParams),
                          sorting: sortChecker('number', filterParams),
                          node: () => (
                            <Box fw='800' txtWrap data-cy={'badge_number'}>
                              {_t('Badge Number')}
                            </Box>
                          ),
                        },
                        cell: {
                          node: (record) => {
                            return (
                              <Box kind={'vflex'} justify='flex-start' h='100%' fw='700' pad={cellPadding} data-cy={`el_badge_label`}>
                                {record.number === 'not-set' ? '' : record.number}
                              </Box>
                            );
                          },
                        },
                      },
                      {
                        width: '2fr',
                        header: {
                          onSort: sortHandler('ownerName', setFilterParams),
                          sorting: sortChecker('ownerName', filterParams),
                          node: () => (
                            <Box kind={'vflex'} fw='800' data-cy={'badge_owner'}>
                              <Box>{_t('Badge')}</Box>
                              <Box>{_t('Owner')}</Box>
                            </Box>
                          ),
                        },
                        cell: {
                          node: (record) => {
                            return (
                              <Box kind={'vflex'} justify='flex-start' h='100%' fw='700' data-cy={`el_badge_owner`} txtWrap pad={cellPadding}>
                                {record.ownerName}
                              </Box>
                            );
                          },
                        },
                      },
                      {
                        width: '2fr',
                        header: {
                          onSort: sortHandler('comment', setFilterParams),
                          sorting: sortChecker('comment', filterParams),
                          node: () => (
                            <Box kind={'vflex'} fw='800' data-cy={'cost_center'}>
                              <Box>{_t('Cost')}</Box>
                              <Box>{_t('Center')}</Box>
                            </Box>
                          ),
                        },
                        cell: {
                          node: (record) => {
                            return (
                              <Box h='100%' fw='700' kind={'hflex'} gap='xxs' align='flex-start' justify='space-between' txtWrap pad={cellPadding}>
                                <span data-cy={'el_cost_center'}>{record.comment}</span>
                                {canBadgeBeEdited(record) ? (
                                  <ButtonV2
                                    dataCy='el_cost_center_edit'
                                    onClick={async () => {
                                      const customComment = await showBadgeComment({ kind: 'card', currentComment: record.comment || '' });
                                      if (customComment === false || customComment === null) return;

                                      await api.chargingCards.changeComment({
                                        badgeNumber: record.extId,
                                        comment: customComment,
                                        client: filterParams.clientCode!,
                                        hierarchyNodeCodes: filterParams.nodes || [filterParams.clientCode!],
                                      });

                                      cardsRespRefresh();
                                    }}
                                    apperance='icon'
                                    className={styles.editCustomName}
                                  >
                                    <Ico file={<EditIcon />} />
                                  </ButtonV2>
                                ) : (
                                  cardsResp?.can?.clientCardWorkEdit && (
                                    <div
                                      title={_t('Only cost centers from badges with the service type WORK can be edited. Badges provided by leasing partners cannot be edited.')}
                                    >
                                      <Ico file={<InfoIcon />} />
                                    </div>
                                  )
                                )}
                              </Box>
                            );
                          },
                        },
                      },
                      {
                        width: '2fr',
                        header: {
                          onSort: sortHandler('label', setFilterParams),
                          sorting: sortChecker('label', filterParams),
                          node: () => (
                            <Box kind={'vflex'} fw='800' data-cy={'badge_label'}>
                              <Box>{_t('Badge Label')}</Box>
                            </Box>
                          ),
                        },
                        cell: {
                          node: (record) => {
                            return (
                              <Box h='100%' fw='400' kind={'hflex'} gap='xxs' align='flex-start' justify='space-between' txtWrap pad={cellPadding}>
                                <span data-cy='el_badge_label'>{record.label}</span>
                                {canBadgeBeEdited(record) ? (
                                  <ButtonV2
                                    dataCy='el_badge_label_edit'
                                    onClick={async () => {
                                      const customName = await showBadgeCustomName({ kind: 'card', currentName: record.label || '' });
                                      if (customName === false || customName === null) return;

                                      await api.chargingCards.changeLabel({
                                        badgeNumber: record.extId,
                                        label: customName,
                                        client: filterParams.clientCode!,
                                        hierarchyNodeCodes: filterParams.nodes || [filterParams.clientCode!],
                                      });

                                      cardsRespRefresh();
                                    }}
                                    apperance='icon'
                                    className={styles.editCustomName}
                                  >
                                    <Ico file={<EditIcon />} />
                                  </ButtonV2>
                                ) : (
                                  cardsResp?.can?.clientCardWorkEdit && (
                                    <div title={_t('Only labels from badges with the service type WORK can be edited. Badges provided by leasing partners cannot be edited.')}>
                                      <Ico file={<InfoIcon />} />
                                    </div>
                                  )
                                )}
                              </Box>
                            );
                          },
                        },
                      },
                      {
                        width: '1fr',
                        header: {
                          onSort: sortHandler('rateServiceType', setFilterParams),
                          sorting: sortChecker('rateServiceType', filterParams),
                          node: () => (
                            <Box kind={'vflex'} fw='800' txtWrap data-cy={'standard_service'}>
                              {_t('Standard Service')}
                            </Box>
                          ),
                        },
                        cell: {
                          node: (record) => {
                            return (
                              <Box h='100%' kind={'vflex'} justify={'flex-start'} pad={cellPadding} data-cy={'el_standard_service'}>
                                <Badge testId={'standard-service'} fg={contrastColor(rateTypeColor(record.rateServiceType))} bg={rateTypeColor(record.rateServiceType)}>
                                  {rateTypeTranslation(record.rateServiceType)}
                                </Badge>
                              </Box>
                            );
                          },
                        },
                      },
                      {
                        width: '1fr',
                        header: {
                          onSort: sortHandler('totalEnergy', setFilterParams),
                          sorting: sortChecker('totalEnergy', filterParams),
                          node: () => (
                            <Box kind={'vflex'} fw='800' ellipses tooltip={`${_t('Used Service')}\n${_t('+ Energy (kWh)')}`} data-cy={'used_service'}>
                              <Box>{_t('Used Service')}</Box>
                              <Box>{_t('+ Energy (kWh)')}</Box>
                            </Box>
                          ),
                        },
                        subHeader: {
                          node: (records) => {
                            return (
                              <Box fw='800' kind={'hflex'} justify='flex-start' align='center' h='100%'>
                                {formatEnergy(cardsResp?.totalEnergy, user?.preferences.languageCode, 2)}
                              </Box>
                            );
                          },
                        },
                        cell: {
                          node: (record) => {
                            // ℹ️ uncomment to check multiple service types
                            // if (record.serviceTypeEnergies.length > 0 && record.serviceTypeEnergies.length < 2) {
                            //   record.serviceTypeEnergies.push({ ...record.serviceTypeEnergies[0], serviceType: RateServiceType.PUBLIC, energy: 10 })
                            // }
                            return (
                              <Box fw='700' kind={'vflex'} gap='s' justify='flex-start' h='100%' pad={cellPadding} data-cy={'el_used_energy'}>
                                {record.serviceTypeEnergies.map((e, idx) => (
                                  <Box key={idx} kind={'hflex'} gap='xxs' justify='space-between' align='center' wrap>
                                    <Badge testId={'used-service'} fg={contrastColor(rateTypeColor(e.serviceType))} bg={rateTypeColor(e.serviceType)}>
                                      {rateTypeTranslation(e.serviceType)}
                                    </Badge>
                                    <Box>{formatEnergy(e.energy, user?.preferences.languageCode, 2)}</Box>
                                  </Box>
                                ))}

                                {record.serviceTypeEnergies.length === 0 && (
                                  <Box kind={'vflex'} justify='space-between' align='flex-start'>
                                    <Box>{formatEnergy(record.totalEnergy, user?.preferences.languageCode, 2)}</Box>
                                  </Box>
                                )}
                              </Box>
                            );
                          },
                        },
                      },
                      {
                        width: '1fr',
                        header: {
                          node: () => (
                            <Box kind={'vflex'} fw='800' data-cy={'status'}>
                              <Box>{_t('Status')}</Box>
                            </Box>
                          ),
                        },
                        cell: {
                          node: (record) => {
                            // record.
                            return (
                              <Box h='100%' kind={'vflex'} justify='flex-start' pad={cellPadding} data-cy={'el_status'}>
                                {record.onGoingSessions > 0 && <DotBadge circleColor={'primary-500'}>{_t('Charging')}</DotBadge>}
                                {record.onGoingSessions <= 0 && <DotBadge circleColor={'brand-neutral-300'}>{_t('Finished')}</DotBadge>}
                              </Box>
                            );
                          },
                        },
                      },
                    ]}
                  />
                  <Pagingation
                    skip={cardsResp.skip}
                    limit={cardsResp.limit}
                    total={cardsResp.total}
                    onChange={(arg) => {
                      setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
                    }}
                  />
                </Box>
              </Box>
            );
          }}
        </ApiBasedContent>

        <CustomNamePopup {...badgeCustomNameProps} />
        <CommentPopup {...badgeCommentProps} />
      </PageContent>
    </Page>
  );
}
