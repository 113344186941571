import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { ReactComponent as CheckIco } from '../../../../assets/check.svg';
import { ReactComponent as CompanyIco } from '../../../../assets/company.svg';
import { ReactComponent as DownloadIco } from '../../../../assets/download.svg';
import { ReactComponent as InfoIco } from '../../../../assets/info.svg';
import { ReactComponent as MagnifyingGlassIco } from '../../../../assets/magnifying-glass.svg';
import { ReactComponent as PlusIco } from '../../../../assets/plus.svg';
import { ReactComponent as UserIdIco } from '../../../../assets/user-id.svg';
import { AffiliateChooser } from '../../../../components-v2/affiliate-chooser/affiliate-chooser';
import Badge from '../../../../components-v2/badge/badge';
import ButtonV2, { ButtonToggle, ButtonToggles, ButtonV2Primary } from '../../../../components-v2/button';
import DataTableV2, { sortChecker, sortHandler } from '../../../../components-v2/data-table/data-table';
import FilterToggle from '../../../../components-v2/filter-toggle/filter-toggle';
import Ico from '../../../../components-v2/ico';
import InputV2, { FormContainerV2 } from '../../../../components-v2/input';
import { ApiBasedContent, Page, PageContent, PageHeader, PageTitle } from '../../../../components-v2/page/page';
import Pagingation from '../../../../components-v2/pagination/pagination';
import SingleSelectV2 from '../../../../components-v2/select/single-select';
import TabPanel, { Tab, TabContent, Tabs } from '../../../../components-v2/tab-panel';
import Box, { Colors, Divider, Spaces2, StackedSkeleton, TableRowSkeleton } from '../../../../components-v2/utils';
import RolePermissionsPopup from '../../../../components/role-permissions-popup';
import { SelectedTag, SelectedTagArea } from '../../../../components/tag-selector-v2';
import { useApi } from '../../../../hooks/useApi';
import { createQueryString, usePageParams } from '../../../../hooks/usePageParams';
import api from '../../../../services/api';
import { RoleType, UserListRoleDto } from '../../../../services/api-client/csp-api';
import { pagingValidator, validateString } from '../../../../utils/queryParamValidators';
import useBetterTranslate from '../../../../utils/translation-utils';
import { PermissionsGroupsOfRole, getPermissionGroupStructure } from '../../roles/role-create-popup/permissions-groups';
import UserCreatePopup from '../user-create-popup/user-create-popup';
import UserDeletePopup from '../user-delete-popup/user-delete-popup';
import styles from './user-list-page.module.scss';

// const SHOW_CLIENTS_LIMIT = 3;
// const SHOW_ROLES_LIMIT = 3;
const IMPLICIT_FILTERS = ['skip', 'limit', 'clientCode', 'nodes'];

export default function UserListPage() {
  const navigate = useNavigate();
  const { _t } = useBetterTranslate('user-list-popup');

  const SORT_ALLOWED_VALUES = ['firstName', 'mail'];
  const sortingValidator = {
    sortBy: validateString(SORT_ALLOWED_VALUES),
  };

  type FilterParameters = {
    affiliateCode?: string;
    skip?: number | null;
    limit?: number | null;
    search?: string | undefined;
    sortBy?: string;
    sortDesc?: string;
    client?: string;
    root?: string;
    roles?: string[];
    clients?: string[];
  };

  // const [expandedClients, setExpandedClients] = useState<String[]>([]);
  // const [expandedRoles, setExpandedRoles] = useState<String[]>([]);

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, { ...pagingValidator, ...sortingValidator });
  const [searchTerm, setSearchTerm] = useState(filterParams.search);
  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };

  const toggleFilter = (key: keyof FilterParameters) => {
    return () => {
      setFilterParams({ [key]: filterParams[key] ? undefined : '1' });
    };
  };
  const [filterSectionExpanded, setFilterSectionExpanded] = useState(true);
  const clearFilters = () => {
    const changeParams: FilterParameters = {};
    for (const [k] of Object.entries(filterParams)) {
      if (IMPLICIT_FILTERS.includes(k)) continue;
      (changeParams as any)[k as any] = undefined;
    }

    setFilterParams(changeParams);
  };

  const [usersRes, usersFetching, usersErr, refreshResult] = useApi(
    {
      call: async (
        affiliateCode?: string,
        skip?: number | null,
        limit?: number | null,
        query?: string | null,
        client?: string,
        root?: string,
        roleIds?: string[],
        clientCodes?: string[],
        sortBy?: any,
        sortDesc?: string
      ) => {
        if (!affiliateCode) return;
        const res = await api.users.list({
          limit: Number(limit || 20),
          skip: Number(skip || 0),
          search: query || undefined,
          roleIds: roleIds,
          clientCodes: clientCodes,
          client: client === '1',
          root: root === '1',
          sortBy,
          sortDesc: sortDesc === '1',
          affiliateCode,
        });

        return res;
      },
      map: (data) => {
        if (!data) return data;
        // setExpandedClients([]);
        // setExpandedRoles([]);
        return data;
      },
    },
    filterParams.affiliateCode,
    filterParams.skip,
    filterParams.limit,
    filterParams.search,
    filterParams.client,
    filterParams.root,
    filterParams.roles,
    filterParams.clients,
    filterParams.sortBy,
    filterParams.sortDesc
  );

  useDebounce(
    () => {
      if (searchTerm === filterParams.search) return;
      setFilterParams({ search: searchTerm });
    },
    800,
    [searchTerm, filterParams.search]
  );

  useEffect(() => {
    setSearchTerm(filterParams.search);
  }, [filterParams.search]);

  const getClientUserPageLink = (clientCode: string, userMail: string) => {
    const baseUrl = `organisation/users/${clientCode}`;
    const query = createQueryString({ search: userMail });
    return `/${baseUrl}?${query}`;
  };

  const [showAddPopup, setShowAddPopup] = useState(false);
  const [editedMail, setEditedMail] = useState<string>();
  const onAddClick = () => {
    setEditedMail(undefined);
    setShowAddPopup(true);
  };
  const onEditClick = (mail: string) => {
    setEditedMail(mail);
    setShowAddPopup(true);
  };
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [mailToDelete, setMailToDelete] = useState('');
  const onDeleteClick = (mail: string) => {
    setMailToDelete(mail);
    setShowDeletePopup(true);
  };

  const getPermissionsOfRole = async (roleId: string) => {
    const res = await api.users.getPermissionsOfRole(filterParams.affiliateCode || '', roleId);
    return res.data.permissions;
  };
  const [currentPermissionGroups, setCurrentPermissionGroups] = useState<PermissionsGroupsOfRole>();
  const onRolesPermissionsClick = async (role: UserListRoleDto) => {
    const permissions = await getPermissionsOfRole(role.id || '');
    const groups = getPermissionGroupStructure(permissions);
    setCurrentPermissionGroups({ roleId: role.id || '', roleName: role.name || '', roleType: role.type as RoleType, permissionsGroups: groups });
  };

  const onExportClick = async () => {
    console.log('called');
    // event.preventDefault();
    // event.stopPropagation();

    const resp = await api.users.export({
      skip: 0,
      limit: 0,
      search: filterParams.search || undefined,
      roleIds: filterParams.roles,
      clientCodes: filterParams.clients,
      client: filterParams.client === '1',
      root: filterParams.root === '1',
      sortBy: 'mail',
      sortDesc: filterParams.sortDesc === '1',
      affiliateCode: filterParams.affiliateCode || '',
    });
    let filename = 'users.csv';
    const disposition = resp.headers.get('content-disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(resp.data);
    link.download = filename;
    link.click();
  };

  const selectedTags: { id: string; name: string; type: 'client' | 'role' }[] = [];
  for (const r of filterParams.roles || []) {
    const roleOp = usersRes?.roleOptions.find((ro) => ro.id === r);
    if (!roleOp) continue;
    selectedTags.push({ id: roleOp.id, name: roleOp.name, type: 'role' });
  }
  for (const c of filterParams.clients || []) {
    const clientOp = usersRes?.clientOptions.find((co) => co.id === c);
    if (!clientOp) continue;
    selectedTags.push({ id: clientOp.id, name: clientOp.title, type: 'client' });
  }

  const currentTab = 'users';
  return (
    <Page>
      <PageHeader>
        <PageTitle>{_t('Users')}</PageTitle>
      </PageHeader>
      <PageContent>
        <AffiliateChooser
          current={usersRes?.affiliateContext}
          onAffiliateSelected={(affiliateCode) => navigate(`/administration/${currentTab}/${affiliateCode}`)}
          searchAffiliates={(search) => api.profile.accessibleAffiliates({ search: search, permissions: usersRes?.affiliateContext?.requestedPermissions || [] })}
        />
        <TabPanel>
          <Tabs>
            <Tab fillIco dataCy='tab-clients' txt={_t('Clients')} ico={<CompanyIco />} onClick={() => navigate(`/administration/clients/${filterParams?.affiliateCode}`)}></Tab>
            <Tab fillIco dataCy='tab-roles' txt={_t('Roles')} ico={<UserIdIco />} onClick={() => navigate(`/administration/roles/${filterParams?.affiliateCode}`)}></Tab>
            <Tab
              strokeIco
              active={true}
              dataCy='tab-users'
              txt={_t('Users')}
              ico={<CheckIco />}
              onClick={() => navigate(`/administration/users/${filterParams?.affiliateCode}`)}
            ></Tab>
          </Tabs>

          <TabContent active={true}>
            <Box kind={'vflex'} gap='m' h='100%'>
              <Box kind={'hflex'} align='center' gap='m' justify='space-between' pad={['0', '400']}>
                <Box kind={'hflex'} align='center' gap='m'>
                  <FormContainerV2>
                    <InputV2
                      placeholder={_t('Search')}
                      icoSuffix={<Ico fill='primary-500' size='16px' file={<MagnifyingGlassIco />} />}
                      value={searchTerm || ''}
                      onChange={setSearchTerm}
                      type='text'
                    />
                  </FormContainerV2>

                  <FormContainerV2>
                    <SingleSelectV2
                      className={styles.select}
                      placeholder={_t('Roles')}
                      fetchOptions={async (txt) => {
                        if (!txt) return usersRes?.roleOptions || [];
                        const results = (usersRes?.roleOptions || []).filter((item) => item.name.toLowerCase().indexOf(txt.toLowerCase()) >= 0);
                        const mappedRoles = results.map((r) => ({ id: r.id!, name: r.name }));
                        return mappedRoles;
                      }}
                      options={usersRes?.roleOptions || []}
                      createOption={(opt) => <span>{opt.name}</span>}
                      selectedValue={null}
                      renderDisplayValue={(val) => val.name}
                      onChanged={(selected) => {
                        if (!selected) return;
                        let updated = [selected.id, ...(filterParams.roles || [])];
                        updated = _.uniq(updated);
                        setFilterParams({ roles: updated });
                      }}
                      isClearable={true}
                      dataCy='lstRoles'
                    />
                  </FormContainerV2>
                  <FormContainerV2>
                    <SingleSelectV2<{ id: string; title: string }>
                      className={styles.select}
                      placeholder={_t('Clients')}
                      fetchOptions={async (txt) => {
                        if (!txt) return usersRes?.clientOptions || [];
                        const results = (usersRes?.clientOptions || []).filter((item) => item.title.toLowerCase().indexOf(txt.toLowerCase()) >= 0);
                        const mappedClients = results.map((r) => ({ id: r.id!, title: r.title }));
                        return mappedClients;
                      }}
                      options={usersRes?.clientOptions || []}
                      createOption={(opt) => <span>{opt.title}</span>}
                      selectedValue={null}
                      renderDisplayValue={(val) => val.title}
                      onChanged={(selected) => {
                        if (!selected) return;
                        let updated = [selected.id, ...(filterParams.clients || [])];
                        updated = _.uniq(updated);
                        setFilterParams({ clients: updated });
                      }}
                      isClearable={true}
                      dataCy='lstClients'
                    />
                  </FormContainerV2>
                </Box>

                <Box kind={'hflex'} justify='flex-end' gap='m' align='center'>
                  <ButtonV2 size='s' onClick={onExportClick} apperance='secondary'>
                    <Box kind={'hflex'} gap='xs' align='center'>
                      <Ico fill='primary-500' file={<DownloadIco />} size='16px' />
                      {_t('Export emails')}
                    </Box>
                  </ButtonV2>

                  {usersRes?.can?.manageUser && (
                    <ButtonV2Primary size='s' onClick={onAddClick}>
                      <Box kind={'hflex'} gap='xs' align='center'>
                        <Ico fill='primary-white' file={<PlusIco />} size='16px' />
                        {_t('Create new user')}
                      </Box>
                    </ButtonV2Primary>
                  )}
                  <Divider kind='v' />
                  <FilterToggle onClear={clearFilters} showClear={filterSectionExpanded} toggleExpand={() => setFilterSectionExpanded((current) => !current)} />
                </Box>
              </Box>

              {filterSectionExpanded && (
                <Box kind={'hflex'} justify='space-between' gap='m' wrap testId='quick_filters' pad={['0', '400']}>
                  <Box flexGrow={selectedTags.length > 0 ? '1' : undefined}>
                    <SelectedTagArea
                      items={selectedTags}
                      // renderItem={(item) => <Box>{item.name}</Box>}
                      renderItem={(item) => (
                        <SelectedTag
                          key={item.id}
                          label={item.name}
                          onRemove={() => {
                            if (item.type === 'client') {
                              const excluded = (filterParams.clients || []).filter((c) => c !== item.id);
                              setFilterParams({ clients: [...excluded] });
                            } else {
                              const excluded = (filterParams.roles || []).filter((r) => r !== item.id);
                              setFilterParams({ roles: [...excluded] });
                            }
                          }}
                        />
                      )}
                    />
                  </Box>

                  <Box kind={'hflex'} justify='flex-end' align='center'>
                    <ButtonToggles size='xs'>
                      <ButtonToggle circle={{ color: 'primary-neutral-090' }} toggled={filterParams.root === '1'} onClick={toggleFilter('root')}>
                        {_t('Root')}
                      </ButtonToggle>
                      <ButtonToggle circle={{ color: 'dataviz-purple-middle-blue-purple' }} toggled={filterParams.client === '1'} onClick={toggleFilter('client')}>
                        {_t('Client')}
                      </ButtonToggle>
                    </ButtonToggles>
                  </Box>
                </Box>
              )}

              <ApiBasedContent err={usersErr} fetching={usersFetching} resp={usersRes} placeholder={() => <StackedSkeleton skeleton={() => <TableRowSkeleton />} />}>
                {(usersRes) => {
                  const headerPad: Spaces2[] = ['500', '0', '500', '400'];
                  const colPad: Spaces2[] = ['500', '0', '500', '400'];
                  return (
                    <Box kind={'vflex'} justify='space-between' h='100%' gap='l'>
                      <DataTableV2
                        records={usersRes.entries}
                        layout={[
                          {
                            width: '2fr',
                            header: {
                              padding: headerPad,
                              onSort: sortHandler('firstName', setFilterParams),
                              sorting: sortChecker('firstName', filterParams),
                              node: () => <Box fw='800'>{_t('User name')}</Box>,
                            },
                            cell: {
                              ellipsis: true,
                              padding: colPad,
                              node: (record) => <Box fw='800'>{record.fullName}</Box>,
                            },
                          },
                          {
                            width: '3fr',
                            header: {
                              padding: headerPad,
                              onSort: sortHandler('mail', setFilterParams),
                              sorting: sortChecker('mail', filterParams),
                              node: () => <Box fw='800'>{_t('Email address')}</Box>,
                            },
                            cell: {
                              ellipsis: true,
                              padding: colPad,
                              node: (record) => <Box>{record.mail}</Box>,
                            },
                          },

                          {
                            width: '3fr',
                            header: {
                              padding: headerPad,
                              node: () => <Box fw='800'>{_t('Role permissions')}</Box>,
                            },
                            cell: {
                              padding: colPad,
                              node: (record) => {
                                const allItems = record.roles; //[...record.roles, ...record.roles, ...record.roles];
                                const items = allItems
                                  .map((b) => {
                                    return (
                                      <Box kind={'hflex'} gap='s'>
                                        <Box>{b.name}</Box>
                                        <Box onClick={async () => await onRolesPermissionsClick(b)}>
                                          <Ico size='18px' file={<InfoIco />} fill='primary-500' />
                                        </Box>
                                      </Box>
                                    );
                                  })
                                  .flatMap((item, idx) => {
                                    const res = idx !== allItems.length - 1 ? [item, <Divider kind='h' />] : [item];
                                    return res;
                                  });
                                return (
                                  <Box kind={'vflex'} gap='s'>
                                    {items.map((item, i) => (
                                      <Fragment key={i}>{item}</Fragment>
                                    ))}
                                  </Box>
                                );
                              },
                            },
                          },

                          {
                            width: '3fr',
                            header: {
                              padding: headerPad,
                              node: () => <Box fw='800'>{_t('Client')}</Box>,
                            },
                            cell: {
                              ellipsis: true,
                              padding: colPad,
                              node: (record) => {
                                const allItems = record.clients || []; //[...record.roles, ...record.roles, ...record.roles];
                                const items = allItems
                                  .map((b) => {
                                    if (usersRes?.can.manageClientUsers && b && b.title) {
                                      return (
                                        <Box kind={'hflex'} gap='s'>
                                          <NavLink to={getClientUserPageLink(b.id, record.mail)}>
                                            <Box>{b.title}</Box>
                                          </NavLink>
                                        </Box>
                                      );
                                    } else {
                                      return <Box>{b.title}</Box>;
                                    }
                                  })
                                  .flatMap((item, idx) => {
                                    const res = idx !== allItems.length - 1 ? [item, <Divider kind='h' />] : [item];
                                    return res;
                                  });
                                return (
                                  <Box kind={'vflex'} gap='s'>
                                    {items.map((item, i) => (
                                      <Fragment key={i}>{item}</Fragment>
                                    ))}
                                  </Box>
                                );
                              },
                            },
                          },
                          {
                            width: '1fr',
                            header: {
                              padding: headerPad,
                              node: () => <Box fw='800'>{_t('Access')}</Box>,
                            },
                            cell: {
                              padding: colPad,
                              node: (record) => {
                                const allItems = record.roleAccessTypes || []; //[...record.roles, ...record.roles, ...record.roles];
                                const items = allItems
                                  .map((b) => {
                                    const color: Colors = b === 'root' ? 'primary-neutral-090' : 'dataviz-purple-middle-blue-purple';
                                    return (
                                      <Badge key={record.mail + b} bg={color} fg='primary-white'>
                                        {_.startCase(b)}
                                      </Badge>
                                    );
                                  })
                                  .flatMap((item, idx) => {
                                    const res = idx !== allItems.length - 1 ? [item, <Divider kind='h' />] : [item];
                                    return res;
                                  });

                                return (
                                  <Box kind={'vflex'} gap='s'>
                                    {items.map((item, i) => (
                                      <Fragment key={i}>{item}</Fragment>
                                    ))}
                                  </Box>
                                );
                              },
                            },
                          },
                          {
                            width: '220px',
                            header: {
                              padding: headerPad,
                              node: () => <Box fw='800'>{_t('Action')}</Box>,
                            },
                            cell: {
                              padding: colPad,
                              node: (record) => {
                                if (!usersRes?.can.manageUser) return <></>;
                                if (!record.roles.some((r) => r.type === RoleType.Root)) return <></>;
                                return (
                                  <Box kind={'hflex'} gap='s'>
                                    <ButtonV2 apperance='danger' size='s' onClick={() => onDeleteClick(record.mail)}>
                                      {_t('Delete')}
                                    </ButtonV2>
                                    <Divider />
                                    <ButtonV2Primary size='s' onClick={() => onEditClick(record.mail)}>
                                      {_t('Edit')}
                                    </ButtonV2Primary>
                                  </Box>
                                );
                              },
                            },
                          },
                        ]}
                      />

                      <Pagingation
                        skip={usersRes.skip}
                        limit={usersRes.limit}
                        total={usersRes.total}
                        onChange={(arg) => {
                          setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
                        }}
                      />
                    </Box>
                  );
                }}
              </ApiBasedContent>
            </Box>
          </TabContent>
        </TabPanel>
      </PageContent>

      {/* <> */}
      {/* {usersRes && (
          <AdminTabPanel
            selectedTab='users'
            affiliate={usersRes.affiliateContext}
            can={{ viewClientList: usersRes.can.viewClientList, viewRoleList: usersRes.can.viewRoleList, viewUserList: true }}
          />
        )} */}

      {/* <FormCard tabletSize='full'>
          <FormCardBody className={classNames(styles.filters)}>
            <div className={styles.filterArea}>
              <SearchInput placeholder={_t('Benutzer suchen')} maxLength={120} onChange={setSearchTerm} value={searchTerm} dataCy='srcUsers' />
            </div>

            <SingleSelect<{ name: string; id: string }>
              className={styles.roleSelect}
              placeholder={_t('Rollen')}
              fetchOptions={async (txt) => {
                if (!txt) return usersRes?.roleOptions || [];
                const results = (usersRes?.roleOptions || []).filter((item) => item.name.toLowerCase().indexOf(txt.toLowerCase()) >= 0);
                const mappedRoles = results.map((r) => ({ id: r.id!, name: r.name }));
                return mappedRoles;
              }}
              options={usersRes?.roleOptions || []}
              createOption={(opt) => <span>{opt.name}</span>}
              selectedValue={null}
              renderDisplayValue={(val) => val.name}
              onChanged={(selected) => {
                if (!selected) return;
                let updated = [selected.id, ...(filterParams.roles || [])];
                updated = _.uniq(updated);
                setFilterParams({ roles: updated });
              }}
              isClearable={true}
              dataCy='lstRoles'
            />

            <SingleSelect<{ id: string; title: string }>
              className={styles.roleSelect}
              placeholder={_t('Kunde')}
              fetchOptions={async (txt) => {
                if (!txt) return usersRes?.clientOptions || [];
                const results = (usersRes?.clientOptions || []).filter((item) => item.title.toLowerCase().indexOf(txt.toLowerCase()) >= 0);
                const mappedClients = results.map((r) => ({ id: r.id!, title: r.title }));
                return mappedClients;
              }}
              options={usersRes?.clientOptions || []}
              createOption={(opt) => <span>{opt.title}</span>}
              selectedValue={null}
              renderDisplayValue={(val) => val.title}
              onChanged={(selected) => {
                if (!selected) return;
                let updated = [selected.id, ...(filterParams.clients || [])];
                updated = _.uniq(updated);
                setFilterParams({ clients: updated });
              }}
              isClearable={true}
              dataCy='lstClients'
            />

            <div className={styles.roleKinds}>
              <Pill
                selectedClass={colorStyles.components.roleKind.client.base}
                unselectedClass={colorStyles.components.roleKind.client.light}
                selected={filterParams.client === '1'}
                onClick={() => (filterParams.client ? setFilterParams({ client: undefined }) : setFilterParams({ client: '1' }))}
                dataCy='btnClient'
              >
                Client
              </Pill>

              <Pill
                selectedClass={colorStyles.components.roleKind.root.base}
                unselectedClass={colorStyles.components.roleKind.root.light}
                selected={filterParams.root === '1'}
                onClick={() => (filterParams.root ? setFilterParams({ root: undefined }) : setFilterParams({ root: '1' }))}
                dataCy='btnRoot'
              >
                Root
              </Pill>
            </div>

            {usersRes?.can.manageUser && (
              <ButtonPrimary ralign={true} onClick={onAddClick} dataCy='btnCreateUser'>
                {_t('Benutzer erstellen')}
              </ButtonPrimary>
            )}
          </FormCardBody>
        </FormCard>
        <FormCard tabletSize='full'>
          {usersRes && ((filterParams.roles && filterParams.roles.length > 0) || (filterParams.clients && filterParams.clients.length > 0)) && (
            <div className={styles.appliedFilterArea}>
              {filterParams.roles &&
                filterParams.roles.length > 0 &&
                filterParams.roles.map((rId) => {
                  const model = usersRes.roleOptions.find((r) => r.id === rId);
                  if (!model) return <></>;
                  return (
                    <Pill
                      hasClose={true}
                      className={styles.filteredRole}
                      onClick={() => {
                        const excluded = (filterParams.roles || []).filter((r) => r !== model.id);
                        setFilterParams({ roles: [...excluded] });
                      }}
                    >
                      {model.name}
                    </Pill>
                  );
                })}
              {filterParams.clients &&
                filterParams.clients.length > 0 &&
                filterParams.clients.map((rId) => {
                  const model = usersRes.clientOptions.find((c) => c.id === rId);
                  if (!model) return <></>;
                  return (
                    <Pill
                      hasClose={true}
                      className={colorStyles.components.roleKind.client.base}
                      onClick={() => {
                        const excluded = (filterParams.clients || []).filter((c) => c !== model.id);
                        setFilterParams({ clients: [...excluded] });
                      }}
                    >
                      {model.title}
                    </Pill>
                  );
                })}
            </div>
          )}
        </FormCard> */}
      {/* </> */}

      {/* <FormCard tabletSize='full' className=''>
        <FormCardBody>
          <div className={styles.tableArea}>
            <DataTable
              sticky={true}
              sorting={{
                handler: (col, desc) => setFilterParams({ sortBy: col, sortDesc: desc ? '1' : undefined }),
                col: filterParams.sortBy,
                desc: filterParams.sortDesc === '1',
              }}
              records={usersRes?.entries || []}
              dataCy='tblUsers'
              renderer={{
                row: (record, cols) => (
                  <DataTableRow classNames={styles.row} key={record.mail}>
                    {cols}
                  </DataTableRow>
                ),
                cols: [
                  {
                    headerCol: () => <div>{_t('Name')}</div>,
                    name: 'firstName',
                    sortable: true,
                    col: (record) => <span>{record.fullName}</span>,
                    width: '20%',
                    dataCy: 'hdrNameSort',
                  },
                  {
                    headerCol: () => {
                      return (
                        <div className={styles.emailCol}>
                          <div className={styles.title}>{_t('E-Mail')}</div>
                          <div className={styles.ico} title={_t('Download email addresses')}>
                            <DownloadIco onClick={onExportClick}></DownloadIco>
                          </div>
                        </div>
                      );
                    },
                    name: 'mail',
                    sortable: true,
                    col: (record) => <span>{record.mail}</span>,
                    width: '20%',
                    dataCy: 'hdrEmailSort',
                  },
                  {
                    headerCol: () => <div>{_t('Rollen')}</div>,
                    name: 'role',
                    sortable: false,
                    col: (record) => {
                      const isExpanded = !!expandedRoles.find((item) => item === record.mail);
                      const displayRoles = isExpanded ? record.roles : record.roles?.slice(0, SHOW_ROLES_LIMIT);
                      const label = isExpanded
                        ? _t('Weniger anzeigen')
                        : _t(`Mehr anzeigen ({{hiddenCount}} weitere)`, { hiddenCount: (record?.roles?.length || 0) - SHOW_ROLES_LIMIT });
                      const icon = isExpanded ? <ShowMoreIco className={styles.showLess} /> : <ShowMoreIco />;
                      const onClickExpand = () => setExpandedRoles(isExpanded ? expandedRoles.filter((item) => item !== record.mail) : [...expandedRoles, record.mail]);

                      return (
                        <div>
                          {displayRoles.map((r) => (
                            <div className={styles.role} key={record.mail + r.name}>
                              <div className={styles.roleName} key={r.name}>
                                {r.name}
                              </div>
                              <div className={styles.infoIco}>
                                <InfoIco onClick={async () => await onRolesPermissionsClick(r)}></InfoIco>
                              </div>
                            </div>
                          ))}
                          {(record?.roles?.length || 0) > SHOW_ROLES_LIMIT && (
                            <div className={styles.expandItem} onClick={onClickExpand}>
                              {' '}
                              {icon} {label}
                            </div>
                          )}
                        </div>
                      );
                    },
                    width: '15%',
                    dataCy: 'hdrRolesSort',
                  },
                  {
                    headerCol: () => <div>{_t('Zugang')}</div>,
                    name: 'roleKind',
                    sortable: false,
                    col: (record) => {
                      const displayItems = record.roleAccessTypes?.slice(0, 2) || [];
                      const tags = displayItems.map((item) => {
                        const cls = item === 'root' ? colorStyles.components.roleKind.root.base : colorStyles.components.roleKind.client.base;
                        return (
                          <Tag key={record.mail + item} colorStyle={'custom'} className={cls}>
                            {item}
                          </Tag>
                        );
                      });
                      return <div className={classNames(styles.roleKinds)}>{tags}</div>;
                    },
                    width: '8%',
                    dataCy: 'hdrAccessSort',
                  },
                  {
                    headerCol: () => <div>{_t('Kunde')}</div>,
                    name: 'clientTitle',
                    sortable: false,
                    col: (record) => {
                      const isExpanded = !!expandedClients.find((item) => item === record.mail);
                      const displayItems = isExpanded ? record.clients : record.clients?.slice(0, SHOW_CLIENTS_LIMIT);
                      const label = isExpanded
                        ? _t('Weniger anzeigen')
                        : _t(`Mehr anzeigen ({{hiddenCount}} weitere)`, { hiddenCount: (record?.clients?.length || 0) - SHOW_CLIENTS_LIMIT });
                      const icon = isExpanded ? <ShowMoreIco className={styles.showLess} /> : <ShowMoreIco />;
                      const onClickExpand = () => setExpandedClients(isExpanded ? expandedClients.filter((item) => item !== record.mail) : [...expandedClients, record.mail]);

                      if (displayItems) {
                        return (
                          <div>
                            {displayItems.map((client) => {
                              if (usersRes?.can.manageClientUsers && client && client.title) {
                                return (
                                  <div className={classNames(styles.clientItem)} key={record.mail + client.id}>
                                    <NavLink to={getClientUserPageLink(client.id, record.mail)}>
                                      <span className={classNames(styles.client)}>{client?.title}</span>
                                    </NavLink>
                                  </div>
                                );
                              } else if (!usersRes?.can.manageClientUsers) {
                                return <div>{client.title}</div>;
                              } else {
                                return null;
                              }
                            })}
                            {(record?.clients?.length || 0) > SHOW_CLIENTS_LIMIT && (
                              <div className={styles.expandItem} onClick={onClickExpand}>
                                {' '}
                                {icon} {label}
                              </div>
                            )}
                          </div>
                        );
                      }
                    },
                    width: '12%',
                  },
                  {
                    headerCol: () => <div></div>,
                    name: 'actions',
                    sortable: false,
                    ralign: true,
                    col: (record) => (
                      <div className={styles.actions}>
                        {usersRes?.can.manageUser && (
                          <>
                            {record.roles.find((r) => r.type === RoleType.Root) && (
                              <>
                                <ButtonAccent onClick={() => onDeleteClick(record.mail)} className={styles.editBtn} dataCy='btnDelete'>
                                  {_t('Löschen')}
                                </ButtonAccent>
                                <ButtonPrimary onClick={() => onEditClick(record.mail)} className={styles.editBtn} dataCy='btnEdit'>
                                  {_t('Editieren')}
                                </ButtonPrimary>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    ),
                    width: '30%',
                  },
                ],
              }}
            />
          </div>
        </FormCardBody>
      </FormCard>

      {usersRes && (
        <Paging
          skip={usersRes.skip}
          limit={usersRes.limit}
          total={usersRes.total}
          onChange={(arg) => {
            setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
          }}
        />
      )} */}

      <UserCreatePopup
        affiliateCode={filterParams.affiliateCode || ''}
        defaultLang={usersRes?.defaultLanguage || 'en'}
        open={showAddPopup}
        close={() => setShowAddPopup(false)}
        onSubmit={() => refreshResult()}
        mail={editedMail}
        dataCy={{ root: editedMail ? 'popEditUser' : 'popCreateUser', buttonSave: 'btnSave' }}
        getPermissionsOfRole={getPermissionsOfRole}
      />

      <UserDeletePopup
        affiliateCode={filterParams.affiliateCode || ''}
        open={showDeletePopup}
        close={() => setShowDeletePopup(false)}
        onSubmit={() => refreshResult()}
        mail={mailToDelete}
      />
      <RolePermissionsPopup
        open={!!currentPermissionGroups?.roleId}
        permissionsGroups={currentPermissionGroups?.permissionsGroups || []}
        onClose={() => {
          setCurrentPermissionGroups(undefined);
        }}
        roleType={currentPermissionGroups?.roleType as RoleType}
        roleName={currentPermissionGroups?.roleName}
      />
    </Page>
  );
}
