import { ReactComponent as CheckIco } from '../../assets/check.svg';
import { ReactComponent as DownloadIco } from '../../assets/download.svg';
import { ReactComponent as ErrorIco } from '../../assets/error.svg';
import { ReactComponent as FileUploadIco } from '../../assets/file-upload.svg';
import { ReactComponent as MinusIco } from '../../assets/minus.svg';
import { ReactComponent as PlusIco } from '../../assets/plus.svg';
import { ReactComponent as SpinnerIco } from '../../assets/spinner3.svg';
import { ReactComponent as TrashIco } from '../../assets/trash.svg';
import Ico from '../../components-v2/ico';
import { FormContainerV2 } from '../../components-v2/input';
import { ApiBasedContent, Page, PageContent, PageHeader, PageTitle } from '../../components-v2/page/page';
import Box, { ContentBox, Divider, StackedSkeleton, TableRowSkeleton } from '../../components-v2/utils';
import { useApi } from '../../hooks/useApi';
import { usePageParams } from '../../hooks/usePageParams';
import api from '../../services/api';
import st from './document-management-page.module.scss';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Separator } from 'react-contexify';
import { documentTypeColors, documentTypeTransaltion } from '../../components-v2/business-components/domain-mappings';
import ButtonV2, { ButtonSegment, ButtonSegments } from '../../components-v2/button';
import DataTable from '../../components-v2/data-table/data-table';
import { Modal, ModalAcceptButton, ModalCancelButton, ModalContent, ModalFooter, ModalHeader, ModalProps, useModal } from '../../components-v2/modal/modal';
import Pagingation from '../../components-v2/pagination/pagination';
import { NodeHierarchyLabelInverted } from '../../components/org-hierarchy/node-hierarchy-label';
import NodeLabel from '../../components/org-hierarchy/node-label';
import { DocumentSearchResultItem, DocumentStatus, DocumentType, Permission } from '../../services/api-client/csp-api';
import { pagingValidator } from '../../utils/queryParamValidators';
import sleep from '../../utils/sleep';
import useBetterTranslate from '../../utils/translation-utils';

import { useDropzone } from 'react-dropzone';
import { ClientChoosePopup, useClientChooser } from '../../components-v2/business-components/client-choose-popup/client-choose-popup';
import { SmartSuggest } from '../../components-v2/business-components/suggestions/suggestions';
import SingleSelect from '../../components-v2/select/single-select';
import { SelectedTag, TagSuggestion, useTagSuggestion } from '../../components-v2/tag-suggestion/tag-suggestion';
import { SelectedTagArea } from '../../components/tag-selector-v2';
import { useBetterNavigate } from '../../hooks/useBetterNavigate';

type FilterParameters = {
  skip?: number | null;
  limit?: number | null;
  chargeBoxIds?: string[];
  nodeCodes?: string[];
  // sortBy?: string;
  // sortDesc?: string;
  clientCode?: string;
  documentTypes?: DocumentType[];
  // nodes?: string[];
};

export function DocumentManagementPage() {
  const { _t } = useBetterTranslate('document-management');
  const validators = { ...pagingValidator };
  const [expandedStation, setExpandedStation] = useState<string[]>([]);
  const [deleteModalShow, deleteModalProps] = useModal<true, false, { documentId: string; fileName: string }>();
  const [showChangeClientPopup, changeClientPopupProps] = useClientChooser();
  const [uploadDocumentModalShow, uploadDocumentModalProps] = useModal<true, false, UploadDocumentsModalArgs>();
  const navigate = useBetterNavigate<FilterParameters>();

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, validators);
  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };

  const [pageRes, pageResFetching, pageResError, pagesResRefresh] = useApi(
    {
      call: async (clientCode?: string, skip?: number | null, limit?: number | null, chargeBoxIds?: string[], documentTypes?: DocumentType[], nodeCodes?: string[]) => {
        if (!clientCode) return undefined;
        const resp = await api.documentManagement.search({
          clientCode,
          limit: Number(limit || 20),
          skip: Number(skip || 0),
          documentTypes: documentTypes,
          nodeCodes: nodeCodes || [],
          chargeBoxIds: chargeBoxIds || [],
        });

        return resp;
      },
      map: (r) => r,
    },
    filterParams.clientCode,
    filterParams.skip,
    filterParams.limit,
    filterParams.chargeBoxIds,
    filterParams.documentTypes,
    filterParams.nodeCodes
  );

  const toggleDocType = (docType: DocumentType) => {
    const exists = (filterParams.documentTypes || []).some((t) => t === docType);
    if (exists) {
      setFilterParams({ ...filterParams, documentTypes: (filterParams.documentTypes || []).filter((t) => t !== docType) });
    } else {
      setFilterParams({ ...filterParams, documentTypes: [...(filterParams.documentTypes || []), docType] });
    }
  };

  const MAX_SHOW_DOCS = 3;
  const isExpanded = (record: DocumentSearchResultItem) => expandedStation.includes(record.chargeBoxId);
  const getVisibleDocuments = (record: DocumentSearchResultItem) => {
    const filtered = getFilteredDocuments(record);
    if (isExpanded(record)) {
      return filtered;
    }
    return filtered.slice(0, MAX_SHOW_DOCS);
  };
  const getFilteredDocuments = (record: DocumentSearchResultItem) => {
    const docTypes = filterParams.documentTypes || [];
    if (docTypes.length <= 0) return record.documents;
    return record.documents.filter((doc) => docTypes.includes(doc.documentType));
  };

  const onUploadDocumentClicked = async (record: DocumentSearchResultItem) => {
    if (!filterParams.clientCode) return;
    if (!pageRes) return;

    const node = pageRes.hierarchy.find((n) => n.code === record.nodeCode);
    if (!node) return;

    const nodeName = node.title;

    await uploadDocumentModalShow({ chargeBoxId: record.chargeBoxId, locationTxt: nodeName, clientCode: filterParams.clientCode });

    await pagesResRefresh();
  };

  const searchTags = async (txt: string) => {
    if (!pageRes?.clientContext?.code) return [];
    const tagsRes = await api.documentManagement.listTags(pageRes.clientContext.code, { q: txt });
    return tagsRes.data;
  };

  const suggestion = useTagSuggestion({
    fetchOptions: async (val) => searchTags(val),
    createOption: (val) => <SmartSuggest subLabel={val.subLabel} label={val.label || ''} type={val.type} />,
    filterDuplicates: true,
    provideIdentifier: (val) => val.id,
    createSelectedTag: (val, onRemove) => <SelectedTag onRemove={onRemove}>{val.label}</SelectedTag>,
  });

  const hierarchyNodesWrapped = suggestion.selectedItems
    .filter((n) => n.type === 'hierarchy')
    .map((n) => n.id)
    .join(',');
  useEffect(() => {
    const hierarchyNodes = hierarchyNodesWrapped.split(',').filter((n) => !!n);
    // console.log('hierarchyNodesWrapped', hierarchyNodesWrapped);
    setFilterParams({ nodeCodes: hierarchyNodes });
  }, [hierarchyNodesWrapped]);

  const chargeBoxIdsWrapped = suggestion.selectedItems
    .filter((n) => n.type === 'chargepoint')
    .map((n) => n.id)
    .join(',');
  useEffect(() => {
    const chargeBoxIds = chargeBoxIdsWrapped.split(',').filter((n) => !!n);
    // console.log('chargeBoxIdsWrapped', chargeBoxIdsWrapped);
    setFilterParams({ chargeBoxIds: chargeBoxIds });
  }, [chargeBoxIdsWrapped]);

  const downloadDoc = async (documentId: string, chargeBoxId: string) => {
    if (!pageRes?.clientContext?.code) return;

    const resp = await api.documentManagement.getDownloadUrl(pageRes.clientContext.code, { documentId, chargeBoxId });
    const url = resp.data.downloadUrl;
    window.open(url, '_blank');
  };

  return (
    <Page>
      <PageHeader>
        <PageTitle>{_t('Documentmanagement')}</PageTitle>
      </PageHeader>

      <PageContent>
        <Box kind={'hflex'} gap='l' align='center'>
          <Box kind={'hflex'}>
            {pageRes && (
              <ButtonV2
                apperance='primary'
                onClick={async () => {
                  const resp = await showChangeClientPopup({
                    searchClients: (search) => api.profile.accessibleClientNodes({ search: search, permissions: [Permission.ClientDocumentManage] }),
                    clientContext: pageRes.clientContext,
                  });

                  if (resp) {
                    navigate(`/document-management/${resp}`);
                    window.location.reload();
                  }
                }}
              >
                {_t('Switch client organisation')}
              </ButtonV2>
            )}
          </Box>
          <Divider kind='v' />
          <Box fs={'l'} fw='500'>
            {pageRes?.clientContext?.title}
          </Box>
        </Box>

        <ContentBox kind={'vflex'} gap='m' h='100%' pad={['500', '0']}>
          <Box pad={['0', '200']} kind={'vflex'} gap='m'>
            <Box kind={'hflex'} align='center' gap='m' justify='space-between'>
              <Box kind={'hflex'} align='center' gap='m'>
                <FormContainerV2>
                  <TagSuggestion {...suggestion.suggestorProps} />
                </FormContainerV2>
                <ButtonSegments>
                  <ButtonSegment toggled={(filterParams.documentTypes || []).includes(DocumentType.Installation)} onClick={() => toggleDocType(DocumentType.Installation)}>
                    {documentTypeTransaltion(DocumentType.Installation)}
                  </ButtonSegment>
                  <ButtonSegment toggled={(filterParams.documentTypes || []).includes(DocumentType.Maintenance)} onClick={() => toggleDocType(DocumentType.Maintenance)}>
                    {documentTypeTransaltion(DocumentType.Maintenance)}
                  </ButtonSegment>
                  <ButtonSegment toggled={(filterParams.documentTypes || []).includes(DocumentType.Service)} onClick={() => toggleDocType(DocumentType.Service)}>
                    {documentTypeTransaltion(DocumentType.Service)}
                  </ButtonSegment>
                </ButtonSegments>
              </Box>

              <Box kind={'hflex'} justify='flex-end' gap='m' align='center'>
                {/* todo */}
              </Box>
            </Box>

            <SelectedTagArea {...suggestion.selectedTagAreaProps} />
          </Box>

          <ApiBasedContent resp={pageRes} err={pageResError} fetching={pageResFetching} placeholder={() => <StackedSkeleton skeleton={() => <TableRowSkeleton />} />}>
            {(pageRes) => {
              const hierarchy = pageRes.hierarchy;

              return (
                <>
                  <DataTable
                    records={pageRes.entries}
                    h='100%'
                    layout={[
                      {
                        width: '1fr',
                        header: {
                          node: () => _t('Charge Point ID'),
                        },
                        cell: {
                          node: (item) => <Box fw='600'>{item.chargeBoxId}</Box>,
                        },
                      },
                      {
                        width: '2fr',
                        header: {
                          node: () => (
                            <Box kind={'vflex'} gap='s'>
                              <Box>{_t('Location - Subsidiary')}</Box>
                              <Box>{_t('Area')}</Box>
                            </Box>
                          ),
                        },
                        cell: {
                          node: (item) => (
                            <Box kind={'vflex'} gap='s'>
                              <NodeHierarchyLabelInverted allNodes={hierarchy} code={item.nodeCode} hideArea={true} hideClientRoot={true} />
                              <NodeLabel allNodes={hierarchy} code={item.nodeCode} />
                            </Box>
                          ),
                        },
                      },
                      {
                        width: '3fr',
                        header: {
                          node: () => _t('Documents'),
                        },
                        cell: {
                          node: (r) => (
                            <Box kind={'hflex'} gap='m' h='100%'>
                              <Box kind={'vflex'} gap='s' h='100%' flexGrow='1'>
                                {getFilteredDocuments(r).length <= 0 && (
                                  <Box pad={['500', '200']} radius='8px' kind={'vflex'} justify='center' h='100%' bs='dashed' bc='brand-neutral-200'>
                                    {_t('No uploaded document yet')}
                                  </Box>
                                )}
                                {getFilteredDocuments(r).length > 0 &&
                                  getVisibleDocuments(r).map((doc, i) => (
                                    <Box kind={'vflex'} key={i}>
                                      <Box className={st.documentFileName} pad={'200'} kind={'hflex'} gap='s' align='center' bg='brand-neutral-100' radius='8px'>
                                        <Box onClick={doc.documentStatus === DocumentStatus.Done ? () => downloadDoc(doc.documentId, r.chargeBoxId) : undefined} flexGrow='1'>
                                          {doc.fileName}
                                        </Box>
                                        <>
                                          {(doc.documentStatus === DocumentStatus.Pending || doc.documentStatus === DocumentStatus.Analizing) && (
                                            <Box tooltip={_t('Processing Document')}>
                                              <Ico size='18px' file={<SpinnerIco />} />
                                            </Box>
                                          )}

                                          {doc.documentStatus === DocumentStatus.Failed && (
                                            <Box tooltip={_t('This document could not be processed')}>
                                              <Ico size='18px' file={<ErrorIco />} />
                                            </Box>
                                          )}

                                          {doc.documentStatus === DocumentStatus.Done && (
                                            <Box tooltip={_t('Download Document')} onClick={() => downloadDoc(doc.documentId, r.chargeBoxId)}>
                                              <Ico fill='primary-500' size='18px' file={<DownloadIco />} />
                                            </Box>
                                          )}
                                        </>
                                        <Divider />
                                        <ButtonV2
                                          apperance='icon'
                                          onClick={async () => {
                                            if (!filterParams.clientCode) return;
                                            const ok = await deleteModalShow({ documentId: doc.documentId, fileName: doc.fileName });
                                            if (!ok) return;

                                            if (!filterParams.clientCode) return;

                                            await api.documentManagement.unassignDocument(filterParams.clientCode, {
                                              chargeBoxId: r.chargeBoxId,
                                              documentId: doc.documentId,
                                            });

                                            await pagesResRefresh();
                                          }}
                                        >
                                          <Ico size='18px' file={<TrashIco />} />
                                        </ButtonV2>
                                      </Box>
                                      <Box className={st.documentType} pad={['400', '200']} kind={'hflex'} fw='600' bg={documentTypeColors(doc.documentType)} radius='8px'>
                                        {documentTypeTransaltion(doc.documentType)}
                                      </Box>
                                    </Box>
                                  ))}
                                {getFilteredDocuments(r).length > MAX_SHOW_DOCS && (
                                  <>
                                    <Separator />
                                    <Box kind={'hflex'} align='center' justify='space-between'>
                                      <Box kind={'hflex'} align='center' gap='l' fw='600'>
                                        {isExpanded(r) ? _t('Show less documents') : _t('Show more documents')}
                                        <ButtonV2
                                          onClick={() => {
                                            if (isExpanded(r)) {
                                              setExpandedStation(expandedStation.filter((id) => id !== r.chargeBoxId));
                                            } else {
                                              setExpandedStation([...expandedStation, r.chargeBoxId]);
                                            }
                                          }}
                                          size='s'
                                          className={st.expandBtn}
                                          apperance='secondary'
                                        >
                                          <Ico size='18px' file={isExpanded(r) ? <MinusIco /> : <PlusIco />} />
                                        </ButtonV2>
                                      </Box>
                                      <Box kind={'hflex'} gap='xs'>
                                        {_t('Displaying')}{' '}
                                        <Box fw='600'>
                                          {getVisibleDocuments(r).length} / {getFilteredDocuments(r).length}
                                        </Box>
                                      </Box>
                                    </Box>
                                  </>
                                )}
                              </Box>
                              <Divider />
                              <Box>
                                <ButtonV2 apperance='secondary' onClick={() => onUploadDocumentClicked(r)}>
                                  <Box kind={'hflex'} gap='xxs' align='center'>
                                    <Ico size='18px' file={<PlusIco />} />
                                    {_t('Add Document')}
                                  </Box>
                                </ButtonV2>
                              </Box>
                            </Box>
                          ),
                        },
                      },
                    ]}
                  />

                  <Pagingation
                    skip={pageRes.skip}
                    limit={pageRes.limit}
                    total={pageRes.total}
                    onChange={(arg) => {
                      setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
                    }}
                  />
                </>
              );
            }}
          </ApiBasedContent>
        </ContentBox>
      </PageContent>

      <Modal {...deleteModalProps} appearance='danger'>
        {(close, args) => {
          return (
            <>
              <ModalHeader title={_t('Delete document')} />
              <ModalContent>
                <Box kind={'vflex'} align='center' gap='s'>
                  <Box fw='500'>{_t('Are you sure you want to delete this document?')}</Box>
                  <Box>{args?.fileName}</Box>
                  <Box fw='500' fg='functional-error-full'>
                    {_t('This action is definitive and cannot be undone.')}
                  </Box>
                </Box>
              </ModalContent>
              <ModalFooter>
                <Box kind={'hflex'} gap='l' align='center' justify='center' className={st.modalFooter}>
                  <ModalCancelButton onClick={() => close(false)}>{_t('Cancel delete')}</ModalCancelButton>
                  <Divider />
                  <ModalAcceptButton onClick={() => close(true)}>{_t('Delete document')}</ModalAcceptButton>
                </Box>
              </ModalFooter>
            </>
          );
        }}
      </Modal>

      <UploadDocumentsModal {...uploadDocumentModalProps} />
      <ClientChoosePopup {...changeClientPopupProps} />
    </Page>
  );
}

type UploadDocumentsModalArgs = {
  chargeBoxId: string;
  locationTxt: string;
  clientCode: string;
};

type QueedFile = {
  file: File;
  type?: DocumentType;
  progress: 'pending' | 'uploading' | 'done' | 'error';
};
function UploadDocumentsModal(props: ModalProps<true, false, UploadDocumentsModalArgs>) {
  const { _t } = useBetterTranslate('document-management');
  const [currentStep, setCurrentStep] = useState<'drop-files' | 'set-type' | 'uploading' | 'done'>('drop-files');
  const [fileQueue, setFileQueue] = useState<QueedFile[]>([]);
  const fileTypes = useMemo(() => [DocumentType.Installation, DocumentType.Maintenance, DocumentType.Service].map((o) => ({ id: o, value: o })), []);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const mapped = acceptedFiles.map((f): QueedFile => ({ file: f, progress: 'pending' }));
    setFileQueue((current) => [...current, ...mapped]);
    setCurrentStep('set-type');
  }, []);
  const {
    getRootProps,
    getInputProps,
    rootRef,
    open: openFileChooser,
  } = useDropzone({
    onDrop: onDrop,
    maxFiles: 10,
    maxSize: 10 * 1024 * 1024,
    multiple: true,
    noClick: true,

    accept: {
      'application/pdf': ['.pdf', '.PDF'],
    },
  });

  const open = props.open;
  const dropZoneRef = rootRef.current;
  useEffect(() => {
    if (!dropZoneRef) return;
    const cancelHandler = (e: Event) => {
      e.stopPropagation();
    };

    dropZoneRef?.addEventListener('cancel', cancelHandler);

    return () => {
      dropZoneRef?.removeEventListener('cancel', cancelHandler);
    };
    // track open too as otherwise we will only register here once and than never again
  }, [dropZoneRef, open]);

  const chargeBoxId = props.args?.chargeBoxId;
  const clientCode = props.args?.clientCode;
  useEffect(() => {
    setCurrentStep('drop-files');
    setFileQueue([]);
  }, [chargeBoxId, open]);

  useEffect(() => {
    if (fileQueue.length === 0) {
      setCurrentStep('drop-files');
    }
  }, [fileQueue]);

  const onClickNextSetType = async () => {
    if (fileQueue.find((f) => !f.type)) return;
    if (!chargeBoxId) return;
    if (!clientCode) return;

    setCurrentStep('uploading');

    for (const f of fileQueue) {
      f.progress = 'uploading';
      setFileQueue((current) => [...current]);

      try {
        const initRes = await api.documentManagement.beginDocumentUpload(clientCode, {
          chargeBoxId: chargeBoxId,
          documentType: f.type!,
          fileName: f.file.name,
        });

        await fetch(initRes.data.uploadUrl, {
          method: 'PUT',
          body: f.file,
        });

        await api.documentManagement.completeDocumentUpload(clientCode, initRes.data.id);

        f.progress = 'done';
        setFileQueue((current) => [...current]);
      } catch (e) {
        console.error(`failed to upload file ${f.file.name}`, e);
        f.progress = 'error';
        setFileQueue((current) => [...current]);
      }

      await sleep(3000);
    }
  };

  const canFinishPopup = () => {
    if (fileQueue.length === 0) return true;
    const files = fileQueue.filter((f) => f.progress === 'pending' || f.progress === 'uploading');
    const res = files.length === 0;
    console.log('can finish', res);
    console.log('files', fileQueue);
    return res;
  };

  return (
    <Modal {...props}>
      {(close, args) => {
        return (
          <>
            <ModalHeader title={'Add documents'} />
            {currentStep === 'drop-files' && (
              <>
                <ModalContent>
                  <Box kind={'vflex'} gap='l' minw='500px'>
                    <Box kind={'hflex'} justify='center'>
                      {props.args?.chargeBoxId} - {props.args?.locationTxt}
                    </Box>
                    <Box kind={'vflex'} gap='s'>
                      <Box fw='600'>{_t('Drag and drop a document to upload or browse a file')}</Box>
                      <Box fs={'s'} kind={'hflex'} gap='m' align='center'>
                        {_t('Supported format: .pdf')}
                        <Divider />
                        {_t('Maximum size: 10MB')}
                      </Box>
                    </Box>

                    <Box>
                      <div {...getRootProps()}>
                        <Box
                          kind={'vflex'}
                          gap='m'
                          pad={'200'}
                          bg='brand-primary-500-light'
                          bs='dashed'
                          bc='brand-neutral-200'
                          radius='8px'
                          className={st.uploadArea}
                          align='center'
                          justify='center'
                        >
                          <input {...getInputProps()} />
                          <Box fw='500' fs={'l'} kind={'hflex'} gap='xs' align='center'>
                            <Ico file={<FileUploadIco />} />
                            {_t('Upload a file')}
                          </Box>
                          <Box kind={'hflex'} gap='xxs'>
                            {_t('Drag and drop file here, or')}{' '}
                            <Box underline fg='primary-500' fw='500' onClick={openFileChooser}>
                              {_t('Browse file')}
                            </Box>
                          </Box>
                        </Box>
                      </div>
                    </Box>
                  </Box>
                </ModalContent>
                <ModalFooter>
                  <ModalCancelButton onClick={() => close(false)}>{_t('Cancel')}</ModalCancelButton>
                  <ModalAcceptButton disabled>{_t('Upload')}</ModalAcceptButton>
                </ModalFooter>
              </>
            )}
            {currentStep === 'set-type' && (
              <>
                <ModalContent>
                  <Box kind={'vflex'} gap='l' minw='500px' maxw='500px'>
                    <Box kind={'hflex'} justify='center'>
                      {props.args?.chargeBoxId} - {props.args?.locationTxt}
                    </Box>

                    <Box kind={'vflex'} gap='m'>
                      <Box fw='600'>{_t('Select the type of document')}</Box>
                      <Box fs={'s'} kind={'vflex'} gap='s'>
                        {fileQueue.map((f, i) => {
                          return (
                            <Box key={i} kind={'hgrid'} gap='s'>
                              <Box cellSize='4' kind={'vflex'} justify='center'>
                                <Box ellipses>{f.file.name}</Box>
                              </Box>
                              <Box cellSize='3'>
                                <Box kind={'hflex'} gap='s' align='center'>
                                  <Box flexGrow='1'>
                                    <SingleSelect
                                      options={fileTypes}
                                      fetchOptions={async () => fileTypes}
                                      createOption={(o) => {
                                        return documentTypeTransaltion(o.id);
                                      }}
                                      onChanged={(o) => {
                                        f.type = o?.id;
                                        setFileQueue((current) => [...current]);
                                      }}
                                      placeholder={_t('Select type of document')}
                                      selectedValue={fileTypes.find((o) => o.id === f.type) || null}
                                    />
                                  </Box>
                                  <Divider />

                                  <ButtonV2 autow size='s' apperance='secondary' onClick={() => setFileQueue((current) => current.filter((_, idx) => idx !== i))}>
                                    <Ico size='18px' file={<TrashIco />} />
                                  </ButtonV2>
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                </ModalContent>
                <ModalFooter>
                  <ModalCancelButton onClick={() => close(false)}>{_t('Cancel')}</ModalCancelButton>
                  <ModalAcceptButton disabled={!!fileQueue.find((f) => !f.type)} onClick={() => onClickNextSetType()}>
                    {_t('Upload')}
                  </ModalAcceptButton>
                </ModalFooter>
              </>
            )}
            {currentStep === 'uploading' && (
              <>
                <ModalContent>
                  <Box kind={'vflex'} gap='l' minw='500px' maxw='500px'>
                    <Box kind={'hflex'} justify='center'>
                      {props.args?.chargeBoxId} - {props.args?.locationTxt}
                    </Box>

                    <Box kind={'vflex'} gap='m'>
                      <Box fs={'s'} kind={'vflex'} gap='s'>
                        {fileQueue.map((f, i) => {
                          return (
                            <Box key={i} kind={'hflex'} gap='s' align='center'>
                              <Box>
                                {f.progress === 'pending' && <Ico fill='primary-500' size='24px' file={<SpinnerIco />} />}
                                {f.progress === 'uploading' && <Ico fill='primary-500' size='24px' file={<SpinnerIco />} />}
                                {f.progress === 'done' && <Ico fill='primary-500' size='24px' file={<CheckIco />} />}
                                {f.progress === 'error' && <Ico fill='functional-error-full' size='24px' file={<ErrorIco />} />}
                              </Box>

                              <Box maxw='400px' kind={'vflex'} justify='center'>
                                <Box ellipses>{f.file.name}</Box>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                </ModalContent>
                <ModalFooter>
                  <ModalAcceptButton disabled={!canFinishPopup()} onClick={() => close(true)}>
                    {_t('Close')}
                  </ModalAcceptButton>
                </ModalFooter>
              </>
            )}
          </>
        );
      }}
    </Modal>
  );
}
