import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { ReactComponent as BuildingIco } from '../../../../assets/building.svg';
import { ReactComponent as CheckIco } from '../../../../assets/check.svg';
import { ReactComponent as MagnifyingGlassIco } from '../../../../assets/magnifying-glass.svg';
import { ReactComponent as PlusIco } from '../../../../assets/plus.svg';
import { ReactComponent as UserIdIco } from '../../../../assets/user-id.svg';
import DataTableV2 from '../../../../components-v2/data-table/data-table';

import { ReactComponent as UsersIco } from '../../../../assets/users.svg';
import Ico from '../../../../components-v2/ico';
import InputV2, { FormContainerV2 } from '../../../../components-v2/input';
import { ApiBasedContent, Page, PageContent, PageHeader, PageTitle } from '../../../../components-v2/page/page';
import TabPanel, { Tab, TabContent, Tabs } from '../../../../components-v2/tab-panel';
import Box, { Divider, StackedSkeleton, TableRowSkeleton } from '../../../../components-v2/utils';

import { AffiliateChooser } from '../../../../components-v2/affiliate-chooser/affiliate-chooser';
import ButtonV2, { ButtonV2Primary } from '../../../../components-v2/button';
import Pagingation from '../../../../components-v2/pagination/pagination';
import EditPoup, { EditPopupRow } from '../../../../components/edit-popup';
import { useApi } from '../../../../hooks/useApi';
import { usePageParams } from '../../../../hooks/usePageParams';
import api from '../../../../services/api';
import { ClientListEntryDto } from '../../../../services/api-client/csp-api';
import { pagingValidator } from '../../../../utils/queryParamValidators';
import useBetterTranslate from '../../../../utils/translation-utils';
import ClientCreatePopup from '../client-create-popup/client-create-popup';
import styles from './client-list-page.module.scss';

export default function ClientListPage() {
  const { _t } = useBetterTranslate('client-list-page');
  const navigate = useNavigate();

  type FilterParameters = {
    affiliateCode?: string;
    skip?: number | null;
    limit?: number | null;
    search?: string | undefined;
  };

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, { ...pagingValidator });
  const [searchTerm, setSearchTerm] = useState(filterParams.search);
  const [clientToDelete, setClientToDelete] = useState<ClientListEntryDto | undefined>();
  const [clientToAssignResources, setClientToAssignResources] = useState<ClientListEntryDto | undefined>();
  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };

  const [clientsRes, clientsFetching, clientsErr, refreshResult] = useApi(
    {
      call: async (affiliateCode?: string, skip?: number | null, limit?: number | null, query?: string | null) => {
        if (!affiliateCode) return undefined;
        const res = await api.client.list({
          limit: Number(limit || 20),
          skip: Number(skip || 0),
          search: query || undefined,
          affiliateCode,
        });

        return res;
      },
      map: (data) => {
        if (!data) return data;

        return data;
      },
    },
    filterParams.affiliateCode,
    filterParams.skip,
    filterParams.limit,
    filterParams.search
  );

  useDebounce(
    () => {
      if (searchTerm === filterParams.search) return;
      setFilterParams({ search: searchTerm });
    },
    800,
    [searchTerm, filterParams.search]
  );

  useEffect(() => {
    setSearchTerm(filterParams.search);
  }, [filterParams.search]);

  const [showAddPopup, setShowAddPopup] = useState(false);
  const [selectedClientCode, setSelectedClientCode] = useState<string>();
  const onAddClick = () => {
    navigate(`/administration/clients/${filterParams.affiliateCode}/manage`);
    // setSelectedClientCode(undefined);
    // setShowAddPopup(true);
  };
  const onEditClick = (clientCode: string) => {
    setSelectedClientCode(clientCode);
    setShowAddPopup(true);
  };

  const goToResourceImport = (clientCode: string) => {
    navigate(`/administration/clients/${filterParams.affiliateCode}/import-resources/${clientCode}`);
  };

  const getPermissionsOfRole = async (roleId: string) => {
    const res = await api.client.getPermissionsOfRole(filterParams.affiliateCode || '', roleId);
    return res.data.permissions;
  };
  const currentTab = 'clients';

  return (
    <Page className={styles.root}>
      <PageHeader>
        <PageTitle>{_t('Clients')}</PageTitle>
      </PageHeader>
      <PageContent>
        <>
          <AffiliateChooser
            current={clientsRes?.affiliateContext}
            onAffiliateSelected={(affiliateCode) => navigate(`/administration/${currentTab}/${affiliateCode}`)}
            searchAffiliates={(search) => api.profile.accessibleAffiliates({ search: search, permissions: clientsRes?.affiliateContext?.requestedPermissions || [] })}
          />
          <TabPanel>
            <Tabs>
              <Tab
                fillIco
                dataCy='tab-clients'
                active={true}
                txt={_t('Clients')}
                ico={<CheckIco />}
                onClick={() => navigate(`/administration/clients/${filterParams?.affiliateCode}`)}
              ></Tab>
              <Tab
                fillIco
                dataCy='tab-roles'
                active={false}
                txt={_t('Roles')}
                ico={<UserIdIco />}
                onClick={() => navigate(`/administration/roles/${filterParams?.affiliateCode}`)}
              ></Tab>
              <Tab
                strokeIco
                dataCy='tab-users'
                active={false}
                txt={_t('Users')}
                ico={<UsersIco />}
                onClick={() => navigate(`/administration/users/${filterParams?.affiliateCode}`)}
              ></Tab>
            </Tabs>
            <TabContent active={true}>
              <Box kind={'vflex'} gap='m' h='100%'>
                <Box kind={'hflex'} align='center' gap='m' justify='space-between' pad={['0', '400']}>
                  <FormContainerV2>
                    <InputV2
                      placeholder={_t('Search')}
                      icoSuffix={<Ico fill='primary-500' size='16px' file={<MagnifyingGlassIco />} />}
                      value={searchTerm || ''}
                      onChange={setSearchTerm}
                      type='text'
                    />
                  </FormContainerV2>

                  {clientsRes?.can?.writeClient && (
                    <ButtonV2Primary size='s' onClick={onAddClick}>
                      <Box kind={'hflex'} gap='xs' align='center'>
                        <Ico fill='primary-white' file={<PlusIco />} size='16px' />
                        {_t('Create new client')}
                      </Box>
                    </ButtonV2Primary>
                  )}
                </Box>

                <ApiBasedContent err={clientsErr} fetching={clientsFetching} resp={clientsRes} placeholder={() => <StackedSkeleton skeleton={() => <TableRowSkeleton />} />}>
                  {(clientsRes) => {
                    return (
                      <Box kind={'vflex'} justify='space-between' h='100%' gap='l'>
                        <DataTableV2
                          records={clientsRes.entries}
                          layout={[
                            {
                              width: '3fr',
                              header: {
                                // onSort: sortHandler('entityId', setFilterParams),
                                node: () => <Box fw='800'>{_t('Name')}</Box>,
                              },
                              cell: {
                                ellipsis: true,
                                node: (record) => (
                                  <Box kind={'vflex'}>
                                    <Box fw='800'>{record.title}</Box>
                                    <Box>
                                      {record.code} {record.cpmsId ? `/ ${record.cpmsId}` : ''}
                                    </Box>
                                  </Box>
                                ),
                              },
                            },
                            {
                              width: '2fr',
                              header: {
                                node: () => <Box fw='800'>{_t('Actions')}</Box>,
                              },
                              cell: {
                                node: (record) => (
                                  <Box kind={'hflex'} gap='m' align='center' h='100%' pad={['200', '0']}>
                                    <ButtonV2 onClick={() => setClientToDelete(record)} apperance='danger' size='s'>
                                      {_t('Delete')}
                                    </ButtonV2>
                                    <ButtonV2 onClick={() => setClientToAssignResources(record)} apperance='secondary' size='s'>
                                      {_t('Assign resources')}
                                    </ButtonV2>
                                    <ButtonV2 onClick={() => onEditClick(record.code || '')} apperance='primary' size='s'>
                                      {_t('Edit')}
                                    </ButtonV2>

                                    <Divider />

                                    <ButtonV2
                                      apperance='icon'
                                      onClick={() => {
                                        if (clientsRes?.can?.viewClientOrgStructure) {
                                          navigate(`/organisation/structure/${record.code}`);
                                        }
                                      }}
                                    >
                                      <Ico fill='primary-neutral-080' file={<BuildingIco />} />
                                    </ButtonV2>

                                    {/*

                                  <ButtonV2Primary size='s' >
                                    <Box kind={'hflex'} gap='xs' align='center'>
                                      <Ico fill='primary-white' file={<BuildingIco />} size='16px' />
                                      {_t('Client page')}
                                    </Box>
                                  </ButtonV2Primary> */}
                                  </Box>
                                ),
                              },
                            },
                          ]}
                        />

                        <Pagingation
                          skip={clientsRes.skip}
                          limit={clientsRes.limit}
                          total={clientsRes.total}
                          onChange={(arg) => {
                            setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
                          }}
                        />
                      </Box>
                    );
                  }}
                </ApiBasedContent>
              </Box>
              <ClientCreatePopup
                open={showAddPopup}
                affiliateCode={filterParams.affiliateCode || ''}
                clientCode={selectedClientCode}
                canWriteIndividual={!!clientsRes?.can.writeIndividualRole}
                close={() => setShowAddPopup(false)}
                onSubmit={() => refreshResult()}
                dataCy={{ root: selectedClientCode ? 'popEditClient' : 'popCreateClient', buttonSave: selectedClientCode ? 'btnSave' : 'btnCreate' }}
                getPermissionsOfRole={getPermissionsOfRole}
              />

              <EditPoup
                open={!!clientToDelete}
                onClose={() => setClientToDelete(undefined)}
                title={_t('Kunden Löschen')}
                saveText={_t('Löschen')}
                onSave={async () => {
                  if (!clientToDelete || !filterParams.affiliateCode) return;
                  await api.client.delete(filterParams.affiliateCode, clientToDelete.code);
                  setClientToDelete(undefined);
                  refreshResult();
                }}
                dataCy={{ root: 'popDeleteClient', title: 'popTitle', buttonClose: 'btnClose', buttonCancel: 'btnCancel', buttonSave: 'btnDelete' }}
              >
                {clientToDelete && (
                  <>
                    <EditPopupRow className={classNames(styles.deletePopupRow)} dataCy={{ content: 'txaDeleteClient' }}>
                      {_t('Sind Sie sicher, dass Sie Kunden {{title}} löschen möchten?', { title: clientToDelete.title })}
                    </EditPopupRow>
                    <EditPopupRow className={classNames(styles.deletePopupRow, styles.alert)} dataCy={{ content: 'wamDelete' }}>
                      ⚠️ {_t('Bei der Löschung des Kunden werden alle Daten des Kunden gelöscht.')}
                    </EditPopupRow>
                    {!clientsRes?.can.deleteUser && (
                      <EditPopupRow className={classNames(styles.deletePopupRow, styles.warn)}>
                        ⚠️ {_t('Sie besitzen nicht das Recht Nutzer zu löschen.')} <br />
                        {_t('Nutzer die dem Kunden zugeordnet sind bleiben erhalten.')}
                      </EditPopupRow>
                    )}
                  </>
                )}
              </EditPoup>

              <EditPoup
                open={!!clientToAssignResources}
                onClose={() => setClientToAssignResources(undefined)}
                title={_t('Resource Assignment')}
                cancelText={_t('Cancel')}
                saveText={_t('Confirm')}
                onSave={async () => {
                  if (!clientToAssignResources) {
                    return;
                  }
                  goToResourceImport(clientToAssignResources?.code);
                  setClientToAssignResources(undefined);
                }}
              >
                <>
                  <EditPopupRow className={classNames(styles.deletePopupRow)}>{_t('This action will trigger the automatic update of client hierarchy.')}</EditPopupRow>
                </>
              </EditPoup>
            </TabContent>
          </TabPanel>
        </>
      </PageContent>
    </Page>
  );
}
