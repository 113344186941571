import { useState } from 'react';
import { Modal, ModalContent, ModalHeader, useModal } from './components-v2/modal/modal';
import useBetterTranslate from './utils/translation-utils';
import useEffectAsync from './utils/useEffectAsync';
import Box from './components-v2/utils';

export function NewUrlPopup(props: {}) {
  const [showNewUrlModal, newUrlModalProps] = useModal<true, false, { newUrl: string }>({
    appearDirection: 'appearTop',
  });
  const [modalHasShown, setModalHasShown] = useState(false);
  const { _t } = useBetterTranslate('newUrlPopup');

  useEffectAsync(async () => {
    if (modalHasShown) {
      return;
    }
    let domain = window.location.hostname.toLocaleLowerCase();
    let newUrl = null;
    if (domain === 'csp.ecs-preprod.totalenergies.de') {
      newUrl = 'https://evc-dev.totalenergies.com';
    } else if (domain === 'csp.ecs-prod.totalenergies.de') {
      newUrl = 'https://evc.totalenergies.com';
    }
    if (newUrl) {
      setModalHasShown(true);
      await showNewUrlModal({ newUrl });
    }
  }, [modalHasShown]);

  return (
    <Modal {...newUrlModalProps}>
      {(close, arg) => {
        return (
          <>
            <ModalHeader
              title={
                <>
                  <Box pad={['0', '600']}>{_t('Website address has been changed')}</Box>
                </>
              }
            />
            <ModalContent>
              <Box kind={'vgrid'} gap='m' w={'100%'}>
                <span>{_t('This address will be shut down until the 31st of March 2025. Please use the new address shown below.')}</span>
                <span>{_t('Diese Adresse wird zum 31. März 2025 abgeschaltet. Bitte nutzen Sie ab sofort die neue Adresse unten.')}</span>

                <span>
                  <a href={arg?.newUrl}> {arg?.newUrl}</a>
                </span>
              </Box>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
}
