import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { ReactComponent as CheckIco } from '../../../../assets/check.svg';
import { ReactComponent as CompanyIco } from '../../../../assets/company.svg';
import { ReactComponent as InfoIco } from '../../../../assets/info.svg';
import { ReactComponent as MagnifyingGlassIco } from '../../../../assets/magnifying-glass.svg';
import { ReactComponent as PlusIco } from '../../../../assets/plus.svg';
import { ReactComponent as UsersIco } from '../../../../assets/users.svg';
import { AffiliateChooser } from '../../../../components-v2/affiliate-chooser/affiliate-chooser';
import Badge from '../../../../components-v2/badge/badge';
import ButtonV2, { ButtonToggle, ButtonToggles, ButtonV2Primary } from '../../../../components-v2/button';
import DataTableV2, { sortChecker, sortHandler } from '../../../../components-v2/data-table/data-table';
import FilterToggle from '../../../../components-v2/filter-toggle/filter-toggle';
import Ico from '../../../../components-v2/ico';
import InputV2, { FormContainerV2 } from '../../../../components-v2/input';
import { ApiBasedContent, Page, PageContent, PageHeader, PageTitle } from '../../../../components-v2/page/page';
import Pagingation from '../../../../components-v2/pagination/pagination';
import TabPanel, { Tab, TabContent, Tabs } from '../../../../components-v2/tab-panel';
import Box, { Divider, StackedSkeleton, TableRowSkeleton } from '../../../../components-v2/utils';
import EditPoup, { EditPopupRow } from '../../../../components/edit-popup';
import RolePermissionsPopup from '../../../../components/role-permissions-popup';
import { useApi } from '../../../../hooks/useApi';
import { usePageParams } from '../../../../hooks/usePageParams';
import api from '../../../../services/api';
import { ListRoleEntryDto, RoleType } from '../../../../services/api-client/csp-api';
import { Unarray } from '../../../../ts-type-utils';
import { pagingValidator, validateArrayOfString, validateString } from '../../../../utils/queryParamValidators';
import useBetterTranslate from '../../../../utils/translation-utils';
import { getPermissionGroupStructure, PermissionsGroupsOfRole } from '../role-create-popup/permissions-groups';
import RoleCreatePopup from '../role-create-popup/role-create-popup';
import styles from './role-list-page.module.scss';

const IMPLICIT_FILTERS = ['skip', 'limit', 'clientCode', 'nodes'];

export default function RoleListPage() {
  const TYPE_ALLOWED_VALUES = Object.values(RoleType);
  const SORT_BY_VALUES = ['name', 'type'];

  const roleValidators = {
    serviceType: validateArrayOfString(TYPE_ALLOWED_VALUES),
    sortBy: validateString(SORT_BY_VALUES),
  };

  const { _t } = useBetterTranslate('role-list-page');
  const navigate = useNavigate();

  type FilterParameters = {
    affiliateCode?: string;
    skip?: number | null;
    limit?: number | null;
    name?: string;
    types?: string[];
    sortBy?: string;
    sortDesc?: string;
  };

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>({}, { ...pagingValidator, ...roleValidators });
  const [roleToDelete, setRoleToDelete] = useState<ListRoleEntryDto | undefined>();
  const [associatedUsersTotalCount, setAssociatedUsersTotalCount] = useState<number | undefined>();
  const [associatedUsersWithoutOtherRoleCount, setAssociatedUsersWithoutOtherRoleCount] = useState<number | undefined>();
  const [firstDeletionStepConfirmed, setFirstDeletionStepConfirmed] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState(filterParams.name);
  const [submitError, setSubmitError] = useState<string>();
  const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);

  const setFilterParams = (filter: FilterParameters) => {
    const { skip, ...params } = filter;
    _setInnerFilterParams({ ...params, skip });
  };

  type ArrayToggles = 'types';
  const checkFilterToggle = <K extends ArrayToggles>(key: K, value: NonNullable<Unarray<FilterParameters[K]>>): boolean => {
    return filterParams[key] && (filterParams[key] as any).includes(value);
  };

  const toggleFilter = <K extends ArrayToggles>(key: K, value: NonNullable<Unarray<FilterParameters[K]>>): void => {
    const currentFilters = filterParams[key] || [];
    const updatedFilters = (currentFilters as any).includes(value) ? [...(currentFilters as any).filter((v: any) => v !== value)] : [...(currentFilters as any), value];
    setFilterParams({ [key]: updatedFilters });
  };
  const [filterSectionExpanded, setFilterSectionExpanded] = useState(true);
  const clearFilters = () => {
    const changeParams: FilterParameters = {};
    for (const [k] of Object.entries(filterParams)) {
      if (IMPLICIT_FILTERS.includes(k)) continue;
      (changeParams as any)[k as any] = undefined;
    }

    setFilterParams(changeParams);
  };

  const [rolesRes, rolesFetching, rolesErr, refreshResult] = useApi(
    {
      call: async (affiliateCode?: string, skip?: number | null, limit?: number | null, name?: string, types?: any, sortBy?: any, sortDesc?: string) => {
        if (!affiliateCode) return undefined;
        const res = await api.role.search({
          limit: Number(limit || 20),
          skip: Number(skip || 0),
          name: name,
          types,
          sortBy,
          sortDesc: sortDesc === '1',
          affiliateCode,
        });

        return res;
      },
      map: (data) => {
        if (!data) return data;
        return data;
      },
    },
    filterParams.affiliateCode,
    filterParams.skip,
    filterParams.limit,
    filterParams.name,
    filterParams.types,
    filterParams.sortBy,
    filterParams.sortDesc
  );

  useApi(
    {
      call: async (roleId?: string | undefined) => {
        if (roleId) {
          if (!filterParams.affiliateCode) return;
          const res = await api.role.getAssociatedUsers(filterParams.affiliateCode, { roleId });
          return res;
        }
      },
      map: (clientCount) => {
        setAssociatedUsersTotalCount(clientCount?.total);
        setAssociatedUsersWithoutOtherRoleCount(clientCount?.withoutOtherRole);
        return clientCount;
      },
    },
    roleToDelete?.id
  );

  useDebounce(
    () => {
      if (searchTerm === filterParams.name) return;
      setFilterParams({ name: searchTerm });
    },
    800,
    [searchTerm, filterParams.name]
  );

  useEffect(() => {
    setSearchTerm(filterParams.name);
  }, [filterParams.name]);

  const [showAddPopup, setShowAddPopup] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState<string>();
  const onAddClick = () => {
    setSelectedRoleId(undefined);
    setShowAddPopup(true);
  };
  const onEditClick = (roleId: string) => {
    setSelectedRoleId(roleId);
    setShowAddPopup(true);
  };

  const getPermissionsOfRole = async (roleId: string) => {
    const res = await api.role.getPermissionsOfRole(filterParams.affiliateCode || '', roleId);
    return res.data.permissions;
  };
  const [currentPermissionGroups, setCurrentPermissionGroups] = useState<PermissionsGroupsOfRole>();
  const onRolesPermissionsClick = async (role: ListRoleEntryDto) => {
    const permissions = await getPermissionsOfRole(role.id || '');
    const groups = getPermissionGroupStructure(permissions);
    setCurrentPermissionGroups({ roleId: role.id || '', roleName: role.name || '', roleType: role.type as RoleType, permissionsGroups: groups });
  };

  const tryToSetRoleToDelete = async (roleEntry: ListRoleEntryDto) => {
    if (!filterParams.affiliateCode) return;
    const isAssigned = (await api.role.isRoleAssignedToCurrentUser(filterParams.affiliateCode, { roleId: roleEntry.id || '' })).data;
    if (isAssigned) {
      setShowErrorPopup(true);
    } else {
      setRoleToDelete(roleEntry);
    }
  };

  const currentTab = 'roles';
  return (
    <Page className={styles.root}>
      <PageHeader>
        <PageTitle>{_t('Roles')}</PageTitle>
      </PageHeader>
      <PageContent>
        <>
          <AffiliateChooser
            current={rolesRes?.affiliateContext}
            onAffiliateSelected={(affiliateCode) => navigate(`/administration/${currentTab}/${affiliateCode}`)}
            searchAffiliates={(search) => api.profile.accessibleAffiliates({ search: search, permissions: rolesRes?.affiliateContext?.requestedPermissions || [] })}
          />

          <TabPanel>
            <Tabs>
              <Tab
                fillIco
                dataCy='tab-clients'
                active={false}
                txt={_t('Clients')}
                ico={<CompanyIco />}
                onClick={() => navigate(`/administration/clients/${filterParams?.affiliateCode}`)}
              ></Tab>
              <Tab
                fillIco
                dataCy='tab-roles'
                active={true}
                txt={_t('Roles')}
                ico={<CheckIco />}
                onClick={() => navigate(`/administration/roles/${filterParams?.affiliateCode}`)}
              ></Tab>
              <Tab
                strokeIco
                active={false}
                dataCy='tab-users'
                txt={_t('Users')}
                ico={<UsersIco />}
                onClick={() => navigate(`/administration/users/${filterParams?.affiliateCode}`)}
              ></Tab>
            </Tabs>
            <TabContent active={true}>
              <Box kind={'vflex'} gap='m' h='100%'>
                <Box kind={'hflex'} align='center' gap='m' justify='space-between' pad={['0', '400']}>
                  <FormContainerV2>
                    <InputV2
                      placeholder={_t('Search')}
                      icoSuffix={<Ico fill='primary-500' size='16px' file={<MagnifyingGlassIco />} />}
                      value={searchTerm || ''}
                      onChange={setSearchTerm}
                      type='text'
                    />
                  </FormContainerV2>

                  <Box kind={'hflex'} justify='flex-end' gap='m' align='center'>
                    {rolesRes?.can?.addAnyRole && (
                      <ButtonV2Primary size='s' onClick={onAddClick}>
                        <Box kind={'hflex'} gap='xs' align='center'>
                          <Ico fill='primary-white' file={<PlusIco />} size='16px' />
                          {_t('Create new role')}
                        </Box>
                      </ButtonV2Primary>
                    )}
                    <Divider kind='v' />
                    <FilterToggle onClear={clearFilters} showClear={filterSectionExpanded} toggleExpand={() => setFilterSectionExpanded((current) => !current)} />
                  </Box>
                </Box>

                {filterSectionExpanded && (
                  <Box kind={'hflex'} justify='flex-end' gap='m' wrap testId='quick_filters' pad={['0', '400']}>
                    <ButtonToggles size='xs'>
                      <ButtonToggle
                        circle={{ color: 'primary-neutral-090' }}
                        toggled={checkFilterToggle('types', RoleType.Root)}
                        onClick={() => toggleFilter('types', RoleType.Root)}
                      >
                        {_t('Root')}
                      </ButtonToggle>
                      <ButtonToggle
                        circle={{ color: 'secondary-teal' }}
                        toggled={checkFilterToggle('types', RoleType.Standard)}
                        onClick={() => toggleFilter('types', RoleType.Standard)}
                      >
                        {_t('Standard')}
                      </ButtonToggle>
                      <ButtonToggle
                        circle={{ color: 'dataviz-purple-middle-blue-purple' }}
                        toggled={checkFilterToggle('types', RoleType.Individual)}
                        onClick={() => toggleFilter('types', RoleType.Individual)}
                      >
                        {_t('Individual')}
                      </ButtonToggle>
                    </ButtonToggles>
                  </Box>
                )}

                <ApiBasedContent err={rolesErr} fetching={rolesFetching} resp={rolesRes} placeholder={() => <StackedSkeleton skeleton={() => <TableRowSkeleton />} />}>
                  {(rolesRes) => {
                    return (
                      <Box kind={'vflex'} justify='space-between' h='100%' gap='l'>
                        <DataTableV2
                          records={rolesRes.roles}
                          layout={[
                            {
                              width: '3fr',
                              header: {
                                padding: ['500', '0', '500', '400'],
                                onSort: sortHandler('name', setFilterParams),
                                sorting: sortChecker('name', filterParams),
                                node: () => <Box fw='800'>{_t('Name')}</Box>,
                              },
                              cell: {
                                ellipsis: true,
                                node: (record) => (
                                  <Box kind={'hflex'} gap='s' align='center' h='100%'>
                                    <Box fw='800'>{record.name}</Box>
                                    <Box onClick={async () => await onRolesPermissionsClick(record)}>
                                      <Ico fill='primary-500' size='14px' file={<InfoIco />} />
                                    </Box>
                                  </Box>
                                ),
                              },
                            },
                            {
                              width: '3fr',
                              header: {
                                padding: ['500', '0', '500', '400'],
                                onSort: sortHandler('type', setFilterParams),
                                sorting: sortChecker('type', filterParams),
                                node: () => <Box fw='800'>{_t('Role type')}</Box>,
                              },
                              cell: {
                                ellipsis: true,
                                node: (record) => (
                                  <Box kind={'hflex'} gap='s' align='center' h='100%'>
                                    {record.type === RoleType.Root && (
                                      <Badge fg={'primary-white'} bg={'primary-neutral-090'}>
                                        {_t('Root')}
                                      </Badge>
                                    )}
                                    {record.type === RoleType.Standard && (
                                      <Badge fg={'primary-white'} bg={'secondary-teal'}>
                                        {_t('Standard')}
                                      </Badge>
                                    )}
                                    {record.type === RoleType.Individual && (
                                      <Box kind={'vflex'} gap={'s'}>
                                        <Badge fg={'primary-white'} bg={'dataviz-purple-middle-blue-purple'}>
                                          {_t('Individual')}
                                        </Badge>
                                        {record.clientTitle && <Box>{record.clientTitle}</Box>}
                                      </Box>
                                    )}
                                  </Box>
                                ),
                              },
                            },

                            {
                              width: '1fr',
                              header: {
                                node: () => <Box fw='800'>{_t('Actions')}</Box>,
                                padding: ['500', '0', '500', '400'],
                              },
                              cell: {
                                node: (record) => {
                                  const allowEdit =
                                    record.createdBySystem === false &&
                                    ((record.type === RoleType.Individual && rolesRes?.can.editIndividualRole) ||
                                      (record.type === RoleType.Standard && rolesRes?.can.editStandardRole) ||
                                      (record.type === RoleType.Root && rolesRes?.can.editRootRole));
                                  const allowDelete =
                                    record.createdBySystem === false &&
                                    ((record.type === RoleType.Individual && rolesRes?.can.deleteIndividualRole) ||
                                      (record.type === RoleType.Standard && rolesRes?.can.deleteStandardRole) ||
                                      (record.type === RoleType.Root && rolesRes?.can.deleteRootRole));

                                  return (
                                    <Box kind={'hflex'} gap='m' align='center' h='100%' pad={['200', '0']}>
                                      <ButtonV2 disabled={!allowDelete} onClick={() => tryToSetRoleToDelete(record)} apperance='danger' size='m'>
                                        {_t('Delete')}
                                      </ButtonV2>
                                      <ButtonV2 disabled={!allowEdit} onClick={() => onEditClick(record.id || '')} apperance='primary' size='m'>
                                        {_t('Edit')}
                                      </ButtonV2>
                                    </Box>
                                  );
                                },
                              },
                            },
                          ]}
                        />

                        <Pagingation
                          skip={rolesRes.skip}
                          limit={rolesRes.limit}
                          total={rolesRes.total}
                          onChange={(arg) => {
                            setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
                          }}
                        />
                      </Box>
                    );
                  }}
                </ApiBasedContent>
              </Box>
            </TabContent>
          </TabPanel>
        </>
      </PageContent>

      <RoleCreatePopup
        affiliateCode={filterParams.affiliateCode || ''}
        canWriteRoot={!!rolesRes?.can.writeRootRole}
        canWriteStandard={!!rolesRes?.can.writeStandardRole}
        canWriteIndividual={!!rolesRes?.can.writeIndividualRole}
        open={showAddPopup}
        save={true}
        roleId={selectedRoleId}
        close={() => setShowAddPopup(false)}
        onSubmit={() => refreshResult()}
        dataCy={selectedRoleId ? 'popEditRole' : 'popCreateRole'}
      />

      <EditPoup
        open={!!roleToDelete && (associatedUsersTotalCount !== undefined || associatedUsersWithoutOtherRoleCount !== undefined)}
        onClose={() => {
          setRoleToDelete(undefined);
          setAssociatedUsersTotalCount(undefined);
          setAssociatedUsersWithoutOtherRoleCount(undefined);
          setFirstDeletionStepConfirmed(false);
          setSubmitError(undefined);
        }}
        title={_t('Rolle löschen')}
        dataCy={{ root: 'popDeleteRole', buttonSave: 'btnDelete' }}
        saveText={_t('Löschen')}
        onSave={async () => {
          if (!roleToDelete?.id) return;
          if (firstDeletionStepConfirmed === false && associatedUsersWithoutOtherRoleCount! > 0) {
            setFirstDeletionStepConfirmed(true);
            return;
          }
          try {
            if (!filterParams.affiliateCode) return;
            await api.role.deleteRole({ id: roleToDelete.id, affiliateCode: filterParams.affiliateCode });
          } catch (err: any) {
            setSubmitError('Ein unerwarteter Fehler ist aufgetreten');
            throw err;
          }
          setRoleToDelete(undefined);
          setAssociatedUsersTotalCount(undefined);
          setAssociatedUsersWithoutOtherRoleCount(undefined);
          setFirstDeletionStepConfirmed(false);
          refreshResult();
        }}
        additionalFooterContent={submitError}
        additionalFooterContentClassName={styles.submitError}
      >
        {roleToDelete && (
          <>
            {!firstDeletionStepConfirmed && associatedUsersTotalCount !== undefined && (
              <EditPopupRow className={classNames(styles.deletePopupRow)} dataCy={{ content: 'txaDeleteRole' }}>
                {_t("Sind Sie sicher, dass Sie die Rolle '{{roleName}}' löschen möchten?", { roleName: roleToDelete.name })}
              </EditPopupRow>
            )}

            {!firstDeletionStepConfirmed && associatedUsersTotalCount! > 0 && (
              <EditPopupRow className={classNames(styles.deletePopupRow, styles.alert)} dataCy={{ content: 'wamDelete' }}>
                ⚠️{' '}
                {_t(
                  'Diese Rolle ist mit {{usersCount}} Benutzer(n) verknüpft. Wenn Sie diese Rolle löschen, können diese Benutzer nicht mehr auf den aktuellen Service zugreifen.',
                  { usersCount: associatedUsersTotalCount }
                )}
              </EditPopupRow>
            )}

            {firstDeletionStepConfirmed && associatedUsersWithoutOtherRoleCount! > 0 && (
              <>
                <EditPopupRow className={classNames(styles.deletePopupRow)}>
                  {_t('{{usersCount}} Benutzern ist keine weitere Rolle zugeordnet.', { usersCount: associatedUsersWithoutOtherRoleCount })}
                </EditPopupRow>
                <EditPopupRow className={classNames(styles.deletePopupRow, styles.alert)}>
                  ⚠️ {_t('Benutzer ohne zugeordnete Rollen werden unwiederbringlich gelöscht.')}
                </EditPopupRow>
              </>
            )}
          </>
        )}
      </EditPoup>

      <EditPoup
        open={showErrorPopup}
        onClose={() => {
          setShowErrorPopup(false);
        }}
        title={_t('Rolle löschen')}
        cancelText={_t('Schließen')}
        additionalFooterContent={submitError}
        additionalFooterContentClassName={styles.submitError}
      >
        <EditPopupRow className={classNames(styles.deletePopupRow, styles.alert)}>
          ⚠️ {_t('Der angemeldete Benutzer kann diese Rolle nicht löschen, da diese dem Benutzer selbst zugewiesen ist.')}
        </EditPopupRow>
      </EditPoup>
      <RolePermissionsPopup
        open={!!currentPermissionGroups?.roleId}
        permissionsGroups={currentPermissionGroups?.permissionsGroups || []}
        onClose={() => {
          setCurrentPermissionGroups(undefined);
        }}
        roleType={currentPermissionGroups?.roleType as RoleType}
        roleName={currentPermissionGroups?.roleName}
      />
    </Page>
  );
}
