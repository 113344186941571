import Button from '../../components/button';
import Page, { ListingCardPlaceholder } from '../../components/page';
import styles from './dev-overview-page.module.scss';

export default function DevOverviewPage() {
  return (
    <Page breadCrumb={[{ title: 'DevTools', href: '/dev', active: true }]} placeHolder={<ListingCardPlaceholder />} className={styles.root}>
      <div className={styles.tiles}>
        <Button href='/dev/versions' kind='accent' className={styles.tile}>
          <h4>Microservice Versioning</h4>
          <p>See all connected Microservices and their deployed versions</p>
        </Button>

        <Button href='/dev/translations' kind='accent' className={styles.tile}>
          <h4>Application Translations</h4>
          <p>See all translations & investigate missing ones</p>
        </Button>

        <Button href='/dev/tools' kind='accent' className={styles.tile}>
          <h4>Development Tools</h4>
          <p>See actions for cleanup databasaes or shortcuts for common dev tasks</p>
        </Button>

        <Button href='/dev/gallery' kind='accent' className={styles.tile}>
          <h4>Component Gallery</h4>
          <p>See all available Components</p>
        </Button>
        <Button href='/dev/deployments' kind='accent' className={styles.tile}>
          <h4>Deployments</h4>
          <p>See all projects and their deployment states</p>
        </Button>
        <Button href='/dev/status-log-migration' kind='accent' className={styles.tile}>
          <h4>Status Log Migration</h4>
          <p>Manage migration of the statuslog</p>
        </Button>
      </div>
    </Page>
  );
}
